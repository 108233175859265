import React from 'react'

// Components
import { FormFieldCheckbox } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import { request } from 'core/constants'
import Header from 'components/lib/Header'
import Button from 'components/lib/Button'

import integrations from '../../'

export default class CreateProposalSolicitation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      applicantList: [],
      applicantsSelected: [],
      isLoading: false,
      applicantValid: true,
      message: '',
      applicantsDuplicated: null,
    }
  }

  componentDidMount() {
    this.formatapplicantList()
  }

  async formatapplicantList() {
    //cria lista de pretendentes com as entidades envolidas no processo
    let { clients, guarantors } = this.props
    let applicants = []

    if (clients?.length > 0) {
      const clientRequests = clients.map(async (item) => {
        const client = (await request.get(`{company}/client/${item.id}`)).data

        if (client.cnpj) {
          applicants.push({
            applicant: { ...client, izeeApplicantType: 'INQUILINO-PJ' },
            key: client.email + client.id,
            applicantType: 'OUTROS',
          })
        } else {
          applicants.push({
            applicant: client,
            key: client.email + client.id,
            applicantType: 'INQUILINO',
          })
          if (client.spouse_email) {
            applicants.push({
              applicant: client,
              key: client.spouse_email + client.id,
              applicantType: 'CONJUGE_INQUILINO',
            })
          }
        }
      })

      await Promise.all(clientRequests)
    }

    if (guarantors?.length > 0) {
      const guarantorRequests = guarantors.map(async (item) => {
        const guarantor = (await request.get(`{company}/guarantor/${item.id}`)).data

        if (guarantor.cnpj) {
          applicants.push({
            applicant: { ...guarantor, izeeApplicantType: 'FIADOR-PJ' },
            key: guarantor.email + guarantor.id,
            applicantType: 'OUTROS',
          })
        } else {
          applicants.push({
            applicant: guarantor,
            key: guarantor.email + guarantor.id,
            applicantType: 'FIADOR',
          })
          if (guarantor.spouse_email) {
            applicants.push({
              applicant: guarantor,
              key: guarantor.spouse_email + guarantor.id,
              applicantType: 'CONJUGE_FIADOR',
            })
          }
        }
      })

      await Promise.all(guarantorRequests)
    }
    this.setState({ applicantList: applicants })
  }

  formatName(item) {
    switch (item.applicantType) {
      case 'OUTROS':
        return item.applicant.corporate_name + ' - Empresa'

      case 'INQUILINO':
        return item.applicant.name + ' - Cliente'

      case 'FIADOR':
        return item.applicant.name + ' - Fiador'

      case 'CONJUGE_INQUILINO':
        return item.applicant.spouse_name + ' - Conjuge do(a) cliente'

      case 'CONJUGE_FIADOR':
        return item.applicant.spouse_name + ' - Conjuge do Fiador'
    }
  }

  render() {
    const insufficientCredits =
      this.props.creditAnalysisTickets - this.state.applicantsSelected.length < 0

    return (
      <React.Fragment>
        <Header isTiny isUppercase subtitle={<hr style={{ marginTop: '1rem' }} />}>
          Selecione os pretendentes para criar a Análise
        </Header>
        <Grid>
          <Grid.Col>
            <div className='text-right'>
              <p>Creditos Consumidos: {this.state.applicantsSelected.length}</p>
            </div>
            {insufficientCredits && (
              <div className='mb-4'>
                <p>
                  <b>Quantidade de créditos insuficiente.</b>
                </p>
              </div>
            )}
            {!this.state.applicantValid && (
              <div className='mb-4'>
                <p>
                  Para criar a solicitação informe os dados do pretendente
                  selecionado:
                </p>
                <ul>
                  <li>
                    <b>- Nome/Razão Social</b>
                  </li>
                  <li>
                    <b>- CPF/CNPJ</b>
                  </li>
                  <li>
                    <b>- Renda</b>
                  </li>
                  <li>
                    <b>- Fonte de Renda</b>
                  </li>
                </ul>
              </div>
            )}

            {this.state.applicantsDuplicated && (
              <div className='mb-4'>
                <p>{this.state.applicantsDuplicated}</p>
              </div>
            )}

            {this.state.isLoading ? (
              <div>
                <div style={{ height: '250px', marginTop: 100 }}>
                  <div
                    style={{ margin: '0 auto', padding: '45px 60px' }}
                    className='empty-state-spinner'
                  />
                  <p className='text-center mt-2'>
                    {' '}
                    <strong>{this.state.message}</strong>
                  </p>
                </div>
              </div>
            ) : (
              <div className='create-solicitation-fc'>
                {this.state.applicantList.map((item, index) => {
                  // const isChecked = this.state.applicantsSelected?.key === item.key;
                  const isChecked = this.state.applicantsSelected.filter(
                    (applicant) => applicant.key === item.key,
                  ).length

                  return (
                    <FormFieldCheckbox
                      key={item.key}
                      name={this.formatName(item)}
                      isDisabled={this.props.formIsWorking}
                      onChange={this.handleCheckboxChanges.bind(this, item)}
                      description={this.formatName(item)}
                      // info={item.info}
                      checked={isChecked}
                    />
                  )
                })}
              </div>
            )}

            <div className='text-right mt-3'>
              <Button
                style={{ marginRight: '5px' }}
                isSmall
                disabled={
                  this.state.isLoading ||
                  !this.state.applicantsSelected.length ||
                  !this.state.applicantValid ||
                  insufficientCredits ||
                  this.state.applicantsDuplicated
                }
                onClick={() => this.submit()}
              >
                Criar Solicitação
              </Button>
            </div>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    )
  }

  validateapplicants() {
    let applicants = this.state.applicantsSelected

    let isValid = true

    applicants.map((item) => {
      if (
        item.applicantType == 'OUTROS' &&
        (!item.applicant.corporate_name || !item.applicant.cnpj)
      ) {
        this.setState({ applicantValid: false })
        isValid = false

        return
      }

      if (
        (item.applicantType == 'INQUILINO' || item.applicantType == 'FIADOR') &&
        (!item.applicant.name ||
          !item.applicant.cpf ||
          !item.applicant.income_source)
      ) {
        this.setState({ applicantValid: false })
        isValid = false

        return
      }

      if (
        (item.applicantType == 'CONJUGE_INQUILINO' ||
          item.applicantType == 'CONJUGE_FIADOR') &&
        (!item.applicant.spouse_name ||
          !item.applicant.spouse_cpf ||
          !item.applicant.spouse_income_source)
      ) {
        this.setState({ applicantValid: false })
        isValid = false

        return
      }
    })

    this.setState({ applicantValid: isValid })

    return isValid
  }

  submit() {
    let isValid = this.validateapplicants()

    if (!isValid) {
      return
    }

    let { companyId, companyDocument, proposalId, property, propertyOwner } =
      this.props

    let applicants = JSON.parse(JSON.stringify(this.state.applicantsSelected))

    let index = 0

    let applicantToCreate = applicants.splice(index, 1)[index]

    let applicantsToAdd = applicants

    this.setState({ isLoading: true, message: 'Criando Solicitação...' })
    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'CREATE',
          companyId,
          companyDocument,
          proposalId: proposalId,
          applicant: applicantToCreate.applicant,
          applicantType: applicantToCreate.applicantType,
          property,
          propertyOwner,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao criar a solicitação, retorna o erro
          this.setState({ isLoading: false })
          this.props.onSuccess(success, data, false)

          return
        }

        let successCreate = success

        let dataCreate = data

        let solicitationId = data.app.response.data.id

        if (applicantsToAdd.length > 0) {
          //depois de criar a solicitação adiciona os pretendentes se existir
          this.setState({ message: 'Adicionando pretendentes...' })
          applicantsToAdd.map((applicant, index) => {
            integrations
              .apps({
                appName: 'fichacerta',
                companyId,
                payload: {
                  action: 'APPLICANT_ADD',
                  companyId,
                  companyDocument,
                  proposalId: proposalId,
                  solicitationId: solicitationId,
                  property: property,
                  propertyOwner: propertyOwner,
                  applicant: applicant.applicant,
                  applicantType: applicant.applicantType,
                },
              })
              .then(({ success, data }) => {
                if (!data.app.response.success) {
                  // se deu erro ao adicionar o pretendente, retorna o erro
                  this.setState({ isLoading: false })
                  this.props.onSuccess(success, data, false)

                  return
                }
                // this.setState({ message: 'Pretendente ' + applicant.applicant.name + ' adicionado' });

                if (index + 1 === applicantsToAdd.length) {
                  // se foi o ultimo pretendente adicionado executa o laudo
                  this.executeReport(
                    companyId,
                    proposalId,
                    solicitationId,
                    successCreate,
                    dataCreate,
                  ) // executa o laudo
                }
              })
              .catch((error) => {
                console.log('Fichacerta create error', error)
              })
          })
        } else {
          this.executeReport(
            companyId,
            proposalId,
            solicitationId,
            successCreate,
            dataCreate,
          ) // executa o laudo
        }
      })
      .catch((error) => {
        console.log('Fichacerta create error', error)
      })
  }

  updateApplicantsSelected(list) {
    const counts = {}

    list.forEach(({ applicant }) => {
      const identifiers = [applicant.cpf, applicant.cnpj].filter(
        (id) => id && id.trim() !== '',
      )

      identifiers.forEach((id) => {
        counts[id] = (counts[id] || 0) + 1
      })
    })

    // Filter identifiers with more than one occurrence
    const duplicates = Object.entries(counts).filter(([, count]) => count > 1)

    let duplicated = null

    if (duplicates.length > 0) {
      const duplicatedStr = duplicates
        .map(([id, count]) => `${id} (${count} vezes)`)
        .join(', ')

      duplicated = `Pretendentes duplicados (cpf/cnpj): ${duplicatedStr}`
    }

    this.setState({
      applicantsSelected: list,
      applicantsDuplicated: duplicated,
    })
  }

  handleCheckboxChanges(item, value) {
    let list = this.state.applicantsSelected

    if (value) {
      if (list.filter((listItem) => listItem.key === item.key).length) return
      list.push(item)
    } else {
      list = list.filter((listItem) => listItem.key !== item.key)
    }
    this.setState({ applicantValid: true })
    this.updateApplicantsSelected(list)
  }

  executeReport(companyId, proposalId, solicitationId, successCreate, dataCreate) {
    this.setState({ message: 'Solicitando laudo...' })
    integrations
      .apps({
        // executa o laudo
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_EXECUTE',
          companyId,
          proposalId: proposalId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro na criação do laudo, retorna o erro
          this.setState({ isLoading: false })
          this.props.onSuccess(success, data, false)
          return
        }

        this.setState({ applicantsSelected: [] })
        this.setState({ isLoading: false, message: '' })
        this.props.onSuccess(successCreate, dataCreate, false)
        return { success, data }
      })
      .catch((error) => {
        console.log('Fichacerta create error', error)
      })
  }
}
