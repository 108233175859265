import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import { request } from 'core/constants'
import moment from 'moment'
import _ from 'lodash'

import { baseURL } from 'core/constants'

import { DOCUMENT_LIST_ID } from '../../../constants/documentList'

import { ga } from '../../../services/firebase'

import { goodObject, rawNumber } from 'utils/utils'

import { fieldProps } from 'features/components/formCreator'
import formCreator from 'features/components/formCreator'

import {
  FormFieldSelect,
  FormFieldSwitch,
  FormFieldText,
  FormFieldTextArea,
} from 'components/lib/FormFields'
import DocumentRequest from 'components/DocumentsRequest/DocumentsRequest'
import SubmitButton from 'components/SubmitButton'
import Tooltip from 'components/lib/Tooltip'
import Header from 'components/lib/Header'
import Button from 'components/lib/Button'
import Space from 'components/lib/Space'
import Modal from 'components/lib/Modal'
import Grid from 'components/lib/Grid'
import Text from 'components/lib/Text'
import Icon from 'components/lib/Icon'

import PropertyOwnerSelection from 'features/components/fieldsGroup/PropertyOwnerSelection'
import GuarantorSelection from 'features/components/fieldsGroup/GuarantorSelection'
import PropertySelection from 'features/components/fieldsGroup/PropertySelection'
import ClientSelection from 'features/components/fieldsGroup/ClientSelection'
import Negotiation from 'features/components/fieldsGroup/Negotiation'
import FullAddress from 'features/components/fieldsGroup/FullAddress'
import { FieldContainer } from 'features/components/fieldsGroup/FullAddress/styles'
import PropertyMeta from 'features/components/fieldsGroup/PropertyMeta'

import { OwnershipDate } from './OwnershipDate'
import RedeSeguros from '../../../assets/svgs/RedeSeguros'
import uploadFile from '../../../uploadFile'

export class NewProposal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      proposalType: this.props.defaultValue?.type || 'TYPE_RENTAL',
      clientsDocuments: props.clientDocumentList.map((doc) => ({ name: doc })),
      propertyOwnerDocuments: props.propertyOwnerDocumentList.map((doc) => ({
        name: doc,
      })),
      guarantorsDocuments: props.guarantorDocumentList.map((doc) => ({
        name: doc,
      })),
      guarantee: (this.props.defaultValue || {}).guarantee || { id: 0 },
      guarantors: (this.props.defaultValue || {}).guarantors || [],
      property: (this.props.defaultValue || {}).properties,
      propertyOwners: (this.props.defaultValue || {}).property_owners || [],
      clients: (this.props.defaultValue || {}).clients || [],
      goal_id: (this.props.defaultValue || {}).goal_id || [],
      ownershipDate: null,
      modalIsOpen: false,
      modalContent: null,
      modalTitle: null,
      validate: {
        property: true,
        clients: true,
        property_value: true,
        guarantee: true,
        guarantors: true,
        propertyOwners: true,
        intermediation_fee: true,
        administration_fee: true,
      },
      optionsTypesSelect: [],
      address: {
        zip_code: '',
        address: '',
        state: '',
        city: '',
        neighborhood: '',
        additional_address: '',
        number: '',
      },
      area: '',
      condo_fee: '',
      hobby_boxes: '',
      iptu: '',
      parking_spaces: '',
      real_state_enrollment: '',
      registration: '',
      suggested_rental_value: '',
      suggested_sale_value: '',
      type_id: '',
      intermediation_fee: this.props?.defaultValue?.intermediation_fee || '',
      administration_fee: this.props?.defaultValue?.administration_fee || '',
      negotiation: this.props?.defaultValue?.negotiation || '',
      selectedType:
        props.defaultValue && props.defaultValue.type
          ? props.defaultValue.type.id
          : null,
      fieldErrors: {},
      isUpload: false,
      newAcquisitionProperty: !!this.props.defaultValue?.temporary_property,
    }
  }

  componentDidMount() {
    this.props.createRequest(this.createRequest.bind(this))
    ga.logEvent('proposal_entered')
    ga.logEvent('screen_view', { screen_name: 'proposal-modal' })

    const loadTypes = async () => {
      const response = await request.get('properties-type')
      const data = response
        .map((res) => {
          return {
            ...res,
            name: res.description,
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))

      this.setState({
        optionsTypesSelect: data,
      })
    }

    if (
      this.props?.defaultValue?.type === 'TYPE_ACQUISITION' &&
      this.props?.defaultValue?.temporary_property
    ) {
      const temporaryProperty = this.props.defaultValue.temporary_property

      this.setState({
        address: {
          zip_code: temporaryProperty?.address?.zip_code,
          address: temporaryProperty?.address?.address,
          state: temporaryProperty?.address?.state,
          city: temporaryProperty?.address?.city,
          neighborhood: temporaryProperty?.address?.neighborhood,
          additional_address: temporaryProperty?.address?.additional_address,
          number: temporaryProperty?.address?.number,
          code: temporaryProperty?.code,
        },
        area: temporaryProperty?.area,
        condo_fee: temporaryProperty?.condo_fee,
        gas_consumption_unit_number: temporaryProperty?.gas_consumption_unit_number,
        hobby_boxes: temporaryProperty?.hobby_boxes,
        iptu: temporaryProperty?.iptu,
        light_consumption_unit_number:
          temporaryProperty?.light_consumption_unit_number,
        name: temporaryProperty?.name,
        parking_spaces: temporaryProperty?.parking_spaces,
        real_state_enrollment: temporaryProperty?.real_state_enrollment,
        registration: temporaryProperty?.registration,
        suggested_rental_value: temporaryProperty?.suggested_rental_value,
        suggested_sale_value: temporaryProperty?.suggested_sale_value,
        type_id: temporaryProperty?.type_id,
        water_consumption_unit_number:
          temporaryProperty?.water_consumption_unit_number,
      })
    }

    loadTypes()
  }

  selectGuarantee = (guaranteeId) => {
    if (this.state.guarantee.id === guaranteeId) return false

    const { activeCompanyModel } = this.props

    const companyGuarantees = activeCompanyModel && activeCompanyModel.guarantees

    const selectedGuarantee = _.find(companyGuarantees, {
      id: parseInt(guaranteeId),
    }) || { id: 0 }

    this.setState({ guarantee: selectedGuarantee })
  }

  handleChangeType(proposalType) {
    this.setState({ proposalType })

    if (this.props.proposalId) {
      const proposal = this.props.current?.proposal || this.props.current
      const proposalProperty = proposal.properties[0]

      this.props.updateFieldValues([
        {
          fieldName: 'property_value',
          field: {
            value:
              (this.state.proposalType === 'TYPE_RENTAL'
                ? proposal.sale_value || proposalProperty.suggested_sale_value
                : proposal.rent_value || proposalProperty.suggested_rental_value) ||
              null,
            isValid: true,
          },
        },
      ])
    } else {
      this.props.updateFieldValues([
        {
          fieldName: 'property_value',
          field: {
            value:
              this.state.proposalType === 'TYPE_RENTAL'
                ? this.state.property?.suggested_sale_value
                : this.state.property?.suggested_rental_value || null,
            isValid: true,
          },
        },
      ])
    }
  }

  render() {
    const { activeCompanyModel, formIsWorking } = this.props
    const _guarantees = activeCompanyModel && activeCompanyModel.guarantees
    const companyGuarantees = [..._guarantees]
    const selectedGuarantee = this.state.guarantee
    const listGuarantees = companyGuarantees.filter((item) => item.is_active)

    const {
      modalIsOpen,
      modalContent,
      modalTitle,
      property,
      proposalType,
      type_id,
      area,
    } = this.state

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col isFull>
            <Header
              isTiny
              isUppercase
              subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
              ri
            >
              Tipo da proposta
              {!!this.props.proposalId && (
                <Tooltip description='Não e possível trocar o tipo de proposta'>
                  <Button isBare tabIndex={-1}>
                    <Icon name='fas fa-question-circle fa-xs' isInfo isSmall />
                  </Button>
                </Tooltip>
              )}
            </Header>

            <div className='buttons has-addons'>
              <Button
                isTab
                isSelected={proposalType === 'TYPE_RENTAL'}
                onClick={() =>
                  !this.props.proposalId && this.handleChangeType('TYPE_RENTAL')
                }
              >
                Locação
              </Button>

              <Button
                isTab
                isSelected={proposalType === 'TYPE_SALE'}
                onClick={() =>
                  !this.props.proposalId && this.handleChangeType('TYPE_SALE')
                }
              >
                Venda
              </Button>

              <Button
                isTab
                isSelected={proposalType === 'TYPE_ACQUISITION'}
                onClick={() =>
                  !this.props.proposalId && this.handleChangeType('TYPE_ACQUISITION')
                }
              >
                Captação
              </Button>
            </div>
          </Grid.Col>
        </Grid>

        <Header
          isTiny
          isUppercase
          subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
        >
          {proposalType !== 'TYPE_ACQUISITION' ? 'Identificação' : 'Dados do imóvel'}
        </Header>

        {proposalType === 'TYPE_ACQUISITION' ? (
          <>
            {(!this.props.proposalId || !this.state.newAcquisitionProperty) && (
              <PropertySelection
                {...this.props}
                onSelect={this.selectedPropertyHandler.bind(this)}
                isDisabled={!!this.props.proposalId}
                hasProposal={!!this.props.proposalId}
                isValid={this.state.validate.property}
                errorMessage='Selecione um imóvel ou crie um novo'
                showAddition={!this.props.proposalId}
                onAdditionSelected={() => {
                  this.setState({ newAcquisitionProperty: true })
                  this.clearProperty()
                }}
              />
            )}

            {property ? (
              property.property_owners ? (
                property.property_owners.length <= 0 ? (
                  <Fragment>
                    <Text isDanger>Imóvel sem proprietário</Text>
                    <PropertyOwnerSelection
                      {...this.props}
                      onSelect={this.selectPropertyOwnerHandler.bind(this)}
                      propertyOwners={
                        this.props?.defaultValue?.property_owners || []
                      }
                      showAddition
                    />
                    <Space />
                  </Fragment>
                ) : null
              ) : null
            ) : null}
            {this.state.newAcquisitionProperty && (
              <>
                <Grid>
                  <Grid.Col isNarrow>
                    <FormFieldText
                      label='Código'
                      name='code'
                      style={{ width: 100 }}
                      onChange={(event) => this.handleChange('code', event)}
                      value={this.state?.address?.code || ''}
                      valueDefault={this.state?.address?.code || ''}
                      isDisabled={
                        this.props?.formIsWorking || this.props?.isDisabled
                      }
                    />
                  </Grid.Col>

                  <Grid.Col>
                    <FormFieldText
                      label='Apelido'
                      name='name'
                      onChange={
                        this.props?.handleFieldChanges &&
                        this.props?.handleFieldChanges?.bind(null, 'name')
                      }
                      value={this.state?.name || ''}
                      valueDefault={this.state?.name || ''}
                      isDisabled={
                        this.props?.formIsWorking || this.props?.isDisabled
                      }
                      noValidate
                    />
                  </Grid.Col>
                </Grid>

                <FullAddress
                  {...this.state}
                  defaultValue={this.state.address}
                  isMultiStep
                  handleChange={this.handleChange.bind(this)}
                  adicionalFields={
                    <FieldContainer>
                      <FormFieldSelect
                        style={{ marginBottom: 0 }}
                        {...fieldProps(this.props, 'type')}
                        label='Tipo de imóvel'
                        onChange={(value, field) =>
                          this.handleTypeChange(value, field)
                        }
                        valueDefault={type_id}
                        valueKey='id'
                        options={this.state.optionsTypesSelect}
                      />

                      <FormFieldText
                        {...fieldProps(this.props, 'area')}
                        valueDefault={area}
                        label='Área privativa (m²)'
                        mask={['onlyNumbers']}
                      />
                    </FieldContainer>
                  }
                />

                <PropertyMeta {...this.props} defaultValue={this.state} noValidate />

                <Fragment>
                  <PropertyOwnerSelection
                    {...this.props}
                    onSelect={this.selectPropertyOwnerHandler.bind(this)}
                    propertyOwners={this.props?.defaultValue?.property_owners || []}
                    showAddition
                  />

                  {this.state.validate.propertyOwners === false && (
                    <Text isDanger>Informe pelo menos um proprietário</Text>
                  )}
                </Fragment>

                {!this.props.proposalId && (
                  <DocumentRequest
                    key={'propertyOwnerDocuments'}
                    title='Solicitar documentos aos proprietários'
                    onChange={this.updatePropertyOwnerDocuments.bind(this)}
                    defaultMultiOption={this.props.propertyOwnerDocumentList}
                  />
                )}
              </>
            )}

            <Space />

            <Header
              isTiny
              isUppercase
              subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
            >
              Negociação
            </Header>

            <Grid>
              <Grid.Col>
                <FormFieldText
                  {...fieldProps(this.props, 'intermediation_fee')}
                  label='Taxa de intermediação (%)'
                  mask={['number']}
                />
              </Grid.Col>

              <Grid.Col>
                <FormFieldText
                  {...fieldProps(this.props, 'administration_fee')}
                  label='Taxa de administração (%)'
                  mask={['number']}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col>
                <FormFieldTextArea
                  {...fieldProps(this.props, 'negotiation')}
                  label='Negociação'
                />
              </Grid.Col>
            </Grid>
          </>
        ) : (
          <>
            <PropertySelection
              {...this.props}
              onSelect={this.selectedPropertyHandler.bind(this)}
              isDisabled={!!this.props.proposalId}
              hasProposal={!!this.props.proposalId}
              isValid={this.state.validate.property}
              errorMessage='Informe pelo menos um imóvel'
              showAddition={true}
            />

            {property ? (
              property.property_owners ? (
                property.property_owners.length <= 0 ? (
                  <Fragment>
                    <Text isDanger>Imóvel sem proprietário</Text>
                    <PropertyOwnerSelection
                      {...this.props}
                      onSelect={this.selectPropertyOwnerHandler.bind(this)}
                      showAddition
                    />
                    <Space />
                  </Fragment>
                ) : null
              ) : null
            ) : null}

            {this.props.proposalId ? (
              <Fragment>
                <PropertyOwnerSelection
                  {...this.props}
                  onSelect={this.selectPropertyOwnerHandler.bind(this)}
                  propertyOwners={this.props.defaultValue.property_owners}
                  showAddition
                />
                <Space />
              </Fragment>
            ) : null}

            <ClientSelection
              {...this.props}
              onSelect={this.selectedClientsHandler.bind(this)}
              showAddition
              clients={this.state.clients}
              isValid={this.state.validate.clients}
              errorMessage='Informe pelo menos um cliente'
            />

            {!this.props.proposalId && (
              <DocumentRequest
                key={'clientDocuments'}
                title='Solicitar documentos aos clientes'
                onChange={this.updateClientsDocuments.bind(this)}
                defaultMultiOption={this.props.clientDocumentList}
              />
            )}

            <Space isSmall />

            <Header
              isTiny
              isUppercase
              subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
            >
              Negociação
            </Header>

            <Negotiation
              {...this.props}
              proposalType={proposalType}
              onGoalSelect={this.selectedGoalHandler.bind(this)}
            />

            {!this.props.proposalId ? (
              <OwnershipDate
                label='Posse aproximada'
                onChange={(value) => {
                  this.setState({ ownershipDate: value })
                }}
              />
            ) : null}

            {proposalType === 'TYPE_RENTAL' && (
              <>
                <Grid>
                  <Grid.Col isFull>
                    <Space />

                    <Header
                      isTiny
                      isUppercase
                      isBetween
                      subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
                      rightComponent={() => (
                        <Tooltip description='Seguros Habilitados'>
                          <RedeSeguros />
                        </Tooltip>
                      )}
                    >
                      Garantia
                    </Header>

                    <FormFieldSelect
                      {...fieldProps(this.props, 'guarantee_id')}
                      isFullhd
                      label='Garantia associada'
                      info='Seleciona o tipo de garantia em que este contrato deverá ser utilizado'
                      options={listGuarantees}
                      valueDefault={selectedGuarantee.id}
                      valueKey='id'
                      onChange={this.selectGuarantee.bind(this)}
                    />
                  </Grid.Col>
                </Grid>

                {selectedGuarantee &&
                  selectedGuarantee.code === 'GUARANTEE_GUARANTOR' && (
                    <React.Fragment>
                      <GuarantorSelection
                        onSelect={this.selectedGuarantorsHandler.bind(this)}
                        {...this.props}
                        showAddition
                        guarantors={this.state.guarantors}
                      />

                      {!this.props.proposalId && (
                        <DocumentRequest
                          title='Solicitar documentos aos fiadores'
                          onChange={this.updateGuarantorsDocuments.bind(this)}
                          defaultMultiOption={this.props.guarantorDocumentList}
                        />
                      )}
                    </React.Fragment>
                  )}
              </>
            )}

            <Space isSmall />

            <Header
              isTiny
              isUppercase
              subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}
            >
              Configurações
            </Header>

            <Grid>
              <Grid.Col isFull>
                <FormFieldSwitch
                  {...fieldProps(this.props, 'send_to_owner')}
                  description='Enviar proposta para o proprietário?'
                  name='send_to_owner'
                />

                <FormFieldSwitch
                  {...fieldProps(this.props, 'send_to_client')}
                  description='Enviar proposta para o cliente?'
                  name='send_to_client'
                />
              </Grid.Col>
            </Grid>
          </>
        )}

        <Grid>
          <Grid.Col>
            <SubmitButton
              {...this.props}
              label='Salvar'
              specSelector='submit-new-proposal'
              isLoading={formIsWorking}
            />
          </Grid.Col>
        </Grid>

        <Modal
          title={modalTitle}
          isOpen={modalIsOpen}
          onClose={this.closeModal.bind(this)}
          backgroundContentStyle={{
            display: 'block',
          }}
        >
          {modalContent}
        </Modal>
      </React.Fragment>
    )
  }

  uploadProgress(event) {
    let progress = (event.loaded * 100) / event.total

    this.setState({ progress: Math.round(progress) })
  }

  async sendFile(file, field) {
    if (!field.value) return

    const companyId = this.props.app.activeCompany.id

    this.setState({ isUpload: true, progress: null })

    const documentId = await uploadFile(
      file[0].raw,
      this.uploadProgress.bind(this),
      companyId,
    )

    this.setState({ documentId, isUpload: false })
  }

  handleChange(name, value) {
    const { address } = this.state
    const updatedFields = { ...address }

    if (name === 'fullAddress') {
      updatedFields['zip_code'] = value['cep'] || ''
      updatedFields['neighborhood'] = value['bairro'] || ''
      updatedFields['city'] = value['localidade'] || ''
      updatedFields['address'] = value['logradouro'] || ''
      updatedFields['state'] = value['uf'] || ''

      return this.setState({
        address: updatedFields,
      })
    }

    updatedFields[name] = value

    return this.setState({
      address: updatedFields,
    })
  }

  handleTypeChange(value, field) {
    if (value) {
      this.setState({
        selectedType: value,
      })
      this.props.handleFieldChanges('type_id', value, field)
    }
  }

  updateClientsDocuments(value) {
    let list = []

    list = value.map((item) => ({ name: item.name }))
    this.setState({ clientsDocuments: list })
  }

  updatePropertyOwnerDocuments(value) {
    let list = []

    list = value.map((item) => ({ name: item.name }))
    this.setState({ propertyOwnerDocuments: list })
  }

  updateGuarantorsDocuments(value) {
    let list = []

    list = value.map((item) => ({ name: item.name }))
    this.setState({ guarantorsDocuments: list })
  }

  selectedPropertyHandler(property) {
    this.setState((prevState) => ({
      property: property,
      newAcquisitionProperty: false,
      validate: {
        ...prevState.validate,
        property: true,
      },
    }))

    this.props.updateFieldValues([
      {
        fieldName: 'property_value',
        field: {
          value:
            (this.state.proposalType === 'TYPE_RENTAL'
              ? property.suggested_rental_value
              : property.suggested_sale_value) || null,
          isValid: true,
        },
      },
    ])
  }

  clearProperty() {
    this.setState({ property: null, validate: { property: true } })

    this.props.updateFieldValues([
      {
        fieldName: 'property_value',
        field: {
          value: null,
          isValid: true,
        },
      },
    ])
  }

  selectPropertyOwnerHandler(propertyOwners) {
    this.setState({ propertyOwners })
  }

  selectedClientsHandler(clients) {
    this.setState({ clients })
  }

  selectedGoalHandler(goal_id) {
    this.setState({ goal_id })
  }

  selectedGuarantorsHandler(guarantors) {
    this.setState({ guarantors })
    this.onSelectGuarantor(guarantors.map((guarantor) => guarantor.id))
  }

  onSelectGuarantor(guarantorsIds) {
    this.setState({ guarantorsIds })
  }

  closeModal() {
    this.setState({ modalIsOpen: false, modalContent: null })
  }

  validateForm(fields) {
    const validate = {
      property: true,
      property_value: true,
      clients: true,
      guarantee: true,
      guarantors: true,
    }

    const fieldErrors = {
      guarantee_id: [],
      clients: [],
    }

    if (
      this.props.defaultValue &&
      this.props.defaultValue.guarantee &&
      !this.state.guarantee.id
    ) {
      this.props.fieldErrors['guarantee_id'] = ['selecione uma garantia', true]
      validate.guarantee = false
    }

    if (!fields.property_ids && !this.state.newAcquisitionProperty)
      validate.property = false

    if (!fields.clients || !fields.clients[0]) {
      this.props.fieldErrors['clients'] = ['Informe pelo menos um cliente', true]
      validate.clients = false
    }

    if (Number(fields.property_value.replace(',', '.')) === 0) {
      this.props.fieldErrors['property_value'] = [
        'O valor do aluguel não pode ser zero',
        true,
      ]
      validate.property_value = false
    }

    if (
      !validate.property ||
      !validate.property_value ||
      !validate.clients ||
      !validate.guarantee
    ) {
      this.setState({ validate, fieldErrors })

      document.querySelector('.custom-modal.is-active .custom-modal-body').scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      this.props.cancelProgress()

      return false
    } else return true
  }

  validatePropertyOwner() {
    const { propertyOwners } = this.state
    const validate = {
      propertyOwners: true,
    }

    const fieldErrors = {
      propertyOwners: [],
    }

    if (propertyOwners.length < 1) {
      this.props.fieldErrors['propertyOwners'] = [
        'Informe pelo menos um proprietário',
        false,
      ]
      validate.propertyOwners = false
    }

    this.setState({ validate, fieldErrors })

    if (!validate.propertyOwners) {
      this.props.cancelProgress()

      return false
    } else return true
  }

  validateAcquitisitonFees(fields) {
    const { administration_fee, intermediation_fee } = fields
    const validate = {
      intermediation_fee: true,
      administration_fee: true,
    }
    const fieldErrors = {
      intermediation_fee: [],
      administration_fee: [],
    }

    const parsedIntermediationFee = parseFloat(
      intermediation_fee.replace(/\./g, '').replace(/,/g, '.'),
    )
    const parsedAdministrationFee = parseFloat(
      administration_fee.replace(/\./g, '').replace(/,/g, '.'),
    )

    if (parsedIntermediationFee < 0 || parsedIntermediationFee > 100) {
      this.props.fieldErrors['intermediation_fee'] = [
        'Informe um percentual entre 0 e 100',
        false,
      ]
      validate.intermediation_fee = false
    }

    if (parsedAdministrationFee < 0 || parsedAdministrationFee > 100) {
      this.props.fieldErrors['administration_fee'] = [
        'Informe um percentual entre 0 e 100',
        false,
      ]
      validate.administration_fee = false
    }

    this.setState((prevState) => ({
      validate: {
        ...prevState.validate,
        ...validate,
      },
      fieldErrors: fieldErrors,
    }))

    if (!validate.intermediation_fee || !validate.administration_fee) {
      this.props.cancelProgress()

      return false
    } else {
      return true
    }
  }

  async createRequest(fields) {
    let goodFields = []

    if (this.state.proposalType === 'TYPE_ACQUISITION') {
      if (!fields.property_ids && !this.validatePropertyOwner(fields)) return

      if (!fields.property_ids && !this.state.newAcquisitionProperty) {
        this.setState((prevState) => ({
          validate: {
            ...prevState.validate,
            property: false,
          },
        }))

        return
      }

      if (!this.validateAcquitisitonFees(fields)) return

      const { address, property, propertyOwners, propertyOwnerDocuments } =
        this.state

      goodFields = goodObject(fields, {
        suggested_rental_value: {
          path: 'suggested_rental_value',
          format: (value) => rawNumber(value),
        },
        suggested_sale_value: {
          path: 'suggested_rental_value',
          format: (value) => rawNumber(value),
        },
        condo_fee: {
          path: 'condo_fee',
          format: (value) => rawNumber(value),
        },
        iptu: {
          path: 'iptu',
          format: (value) => rawNumber(value),
        },
        intermediation_fee: {
          path: 'intermediation_fee',
          format: (value) => rawNumber(value),
        },
        administration_fee: {
          path: 'administration_fee',
          format: (value) => rawNumber(value),
        },
      })

      if (propertyOwners && propertyOwners.length > 0) {
        goodFields['property_owner_ids'] = propertyOwners.map(
          (propertyOwner) => propertyOwner.id,
        )
      } else if (property?.property_owners) {
        goodFields['property_owner_ids'] = property?.property_owners?.map(
          (item) => item.id,
        )
      }
      goodFields['address'] = address
      if (this.state?.documentId) {
        goodFields['contract_id'] = this.state.documentId
      }

      const propertyOwnerEntity = 3
      const propertyOwnerUrl = `${baseURL}entity/${propertyOwnerEntity}/upload/file`
      const headers = { 'Access-Control-Allow-Origin': true }

      if (!this.props.proposalId) {
        for (let c in propertyOwners) {
          for (let i in propertyOwnerDocuments) {
            const formData = new FormData()

            formData.append('name', propertyOwnerDocuments[i].name)
            formData.append('owner_document', propertyOwners[c].id)
            formData.append('entity_id', propertyOwnerEntity)
            formData.append('status', 'STATUS_WAITING')
            await request
              .post(propertyOwnerUrl, formData, headers)
              .catch((err) => console.log(err))
          }
        }
      }
      if (this.props.proposalId) {
        this.props.submit(
          `{company}/acquisition/${this.props.proposalId}`,
          goodFields,
          'put',
        )
      } else {
        this.props.submit('{company}/acquisition', goodFields)
      }
    } else {
      if (!this.validateForm(fields)) return

      const {
        clientsDocuments,
        guarantorsDocuments,
        clients,
        property,
        propertyOwners,
        guarantors,
        proposalType,
        goal_id,
      } = this.state

      goodFields = goodObject(fields, {
        ownership_date: {
          path: 'ownership_date',
          format: (value) => moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        },
        [this.state.proposalType === 'TYPE_RENTAL' ? 'rent_value' : 'sale_value']: {
          path: 'property_value',
          format: (value) => rawNumber(value),
        },
      })

      goodFields['guarantee_id'] = this.state.guarantee.id || null
      goodFields['return_to'] = 'http://process.izee.com.br'
      goodFields['name'] = 'Nova proposta!'
      goodFields['type'] = proposalType
      goodFields['goal_id'] = goal_id

      goodFields['ownership_date'] = this.state.ownershipDate

      goodFields['additional_documents'] = this.state.documents

      if (!goodFields.clients || !goodFields.clients[0]) return

      goodFields.clients[0].main = true

      if (this.props.proposalId) {
        goodFields['property_owner_ids'] =
          property && propertyOwners.map((item) => item.id)

        goodFields['guarantor_ids'] = guarantors && guarantors.map((item) => item.id)
      } else {
        goodFields['property_owner_ids'] =
          property && property?.property_owners?.map((item) => item.id)

        goodFields['guarantor_ids'] = guarantors && guarantors.map((item) => item.id)

        if (propertyOwners && propertyOwners.length > 0) {
          goodFields['property_owner_ids'] = propertyOwners.map(
            (propertyOwner) => propertyOwner.id,
          )
        }
      }

      goodFields['guarantors'] =
        this.state.guarantee.code === 'GUARANTEE_GUARANTOR' ? guarantors : []

      if (!this.props.proposalId) {
        const clientEntity = 4
        const guarantorEntity = 5
        const clientUrl = `${baseURL}entity/${clientEntity}/upload/file`
        const guarantorUrl = `${baseURL}entity/${guarantorEntity}/upload/file`
        const headers = { 'Access-Control-Allow-Origin': true }

        for (let c in clients) {
          for (let i in clientsDocuments) {
            const formData = new FormData()

            formData.append('name', clientsDocuments[i].name)
            formData.append('owner_document', clients[c].id)
            formData.append('entity_id', clientEntity)
            formData.append('status', 'STATUS_WAITING')
            await request
              .post(clientUrl, formData, headers)
              .catch((err) => console.log(err))
          }
        }

        if (this.state.guarantee.code === 'GUARANTEE_GUARANTOR' && guarantors) {
          for (let g in guarantors) {
            for (let i in guarantorsDocuments) {
              const formData = new FormData()

              formData.append('name', guarantorsDocuments[i].name)
              formData.append('owner_document', guarantors[g].id)
              formData.append('entity_id', guarantorEntity)
              formData.append('status', 'STATUS_WAITING')
              await request
                .post(guarantorUrl, formData, headers)
                .catch((err) => console.log(err))
            }
          }
        }
      }

      goodFields['open_process_requirements'] = true

      ga.logEvent('proposal_guarantee', { custom_value: this.state.guarantee.name })

      if (this.props.proposalId) {
        this.props.submit(
          `{company}/proposal/${this.props.proposalId}`,
          goodFields,
          'put',
        )
      } else {
        this.props.submit('{company}/proposal', goodFields)
      }
    }
  }
}

export default connect(
  (state) => ({
    clientDocumentList:
      state.app.companyMeta?.documentLists?.find(
        (documentList) =>
          documentList.id === DOCUMENT_LIST_ID.CREATE_PROPOSAL_CLIENT,
      )?.list || [],
    propertyOwnerDocumentList:
      state.app.companyMeta?.documentLists?.find(
        (documentList) => documentList.id === DOCUMENT_LIST_ID.CREATE_PROPOSAL_OWNER,
      )?.list || [],
    guarantorDocumentList:
      state.app.companyMeta?.documentLists?.find(
        (documentList) =>
          documentList.id === DOCUMENT_LIST_ID.CREATE_PROPOSAL_GUARANTOR,
      )?.list || [],
  }),
  {},
)(formCreator(NewProposal))
