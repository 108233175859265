import React, { useState } from 'react'

import { fieldProps } from 'features/components/formCreator'

import { FormFieldText, FormFieldPhone } from 'components/lib/FormFields'
import Header from 'components/lib/Header'
import Button from 'components/lib/Button'
import Grid from 'components/lib/Grid'

import OtherPersons from './OtherPersons'

import { FormSection } from '../../styles'

const JuridicalPerson = ({ formProps }) => {
  const [partners, setPartners] = useState([1])

  const handleAddPartner = () =>
    setPartners((oldState) => [...oldState, oldState[oldState.length - 1] + 1])

  return (
    <>
      <FormSection>
        <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
          Pessoa Juridica
        </Header>

        <Grid>
          <Grid.Col>
            <FormFieldPhone
              {...fieldProps(this.props, 'legal_tenant.phone')}
              label='Telefone *'
              noValidate
            />
          </Grid.Col>
          <Grid.Col>
            <FormFieldText
              mask={['date']}
              label='Data de constituição *'
              {...fieldProps(formProps, 'legal_tenant.foundation_date')}
            />
          </Grid.Col>
          <Grid.Col>
            <FormFieldText
              mask={['date']}
              label='Últ. Alt. Contratual *'
              {...fieldProps(
                formProps,
                'legal_tenant.last_contractual_alteration_date',
              )}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldText
              mask={['number']}
              label='Capital Social *'
              {...fieldProps(formProps, 'legal_tenant.share_capital')}
            />
          </Grid.Col>
          <Grid.Col>
            <FormFieldText
              mask={['number']}
              label='Capital integralizado *'
              {...fieldProps(formProps, 'legal_tenant.paid_in_capital')}
            />
          </Grid.Col>
          <Grid.Col>
            <FormFieldText
              mask={['number']}
              label='Faturamento anual *'
              {...fieldProps(formProps, 'legal_tenant.anual_revenue')}
            />
          </Grid.Col>
        </Grid>
      </FormSection>

      <FormSection>
        <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
          Acionistas/Sócios/Titular
        </Header>

        {partners.map((partner, index) => (
          <OtherPersons key={partner} formProps={formProps} index={index} />
        ))}

        <Button
          style={{ marginTop: '8px' }}
          hasThemeColor
          onClick={handleAddPartner}
        >
          Adicionar Acionistas/Sócios/Titular
        </Button>
      </FormSection>
    </>
  )
}

export default JuridicalPerson
