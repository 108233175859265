import React from 'react'

import { FormFieldText, FormFieldTextArea } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'

import { fieldProps } from '../../formCreator'

import GoalSelection from './components/GoalSelection'

const Negotiation = (props) => {
  const isRental = props.proposalType === 'TYPE_RENTAL'

  const proposal = props.current?.proposal || props.current

  const initialValue = proposal
    ? isRental
      ? proposal.rent_value || proposal.properties[0].suggested_rental_value
      : proposal.sale_value || proposal.properties[0].suggested_sale_value
    : null

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col>
          <FormFieldText
            {...fieldProps(props, 'property_value')}
            label={`Valor ${isRental ? 'do Aluguel' : 'de Venda'}`}
            valueDefault={props.fields?.property_value?.value || initialValue}
            mask={['number']}
          />
        </Grid.Col>

        <Grid.Col>
          <GoalSelection {...props} />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <FormFieldTextArea
            {...fieldProps(props, 'negotiation')}
            label='Negociação'
          />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  )
}

export default Negotiation
