import styled from 'styled-components'

export const TableContainer = styled.div`
  background-color: white;

  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-child(even) {
    background-color: #fafafa;
  }

  & > span:first-child {
    text-align: left;
  }
  & > span {
    flex: 1;
    text-align: left;

    padding: 10px 18px;

    border-right: 1px solid #d1d1d1;
  }

  & > span:last-child {
    text-align: center;

    border-right: none;
  }
`

export const HeadContainer = styled(RowContainer)`
  background-color: #5f6775;

  & > span {
    font-size: 15px;
    font-weight: 500;
    color: white;

    padding: 10px 18px;

    border-right: 1px solid #d1d1d1;
  }
`

export const StyledButton = styled.div`
  button {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${(props) =>
      props.type === 'hire'
        ? '#44cc44'
        : props.type === 'biometry'
        ? '#FF7844'
        : '#93bf85'};
  }
`

export const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;
`
