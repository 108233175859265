import React from 'react'
import withRedux from 'components/redux'
import { request } from 'core/constants'

import * as actions from './actions'
import * as commons from 'utils/UtilityStore/actions'
import * as mainActions from '../../actions'
import Constants from '../../../../constants'

// Components
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import Modal from 'components/lib/Modal'
import Contract from './components/Contract'
import SectionWrapper from 'components/SectionWrapper'
import Text from 'components/lib/Text'
import { deepKey } from 'utils/utils'
import ProcessTask from 'services/ProcessTask'
import ToggleRequirement from 'features/ToggleRequirement'
import HeadRight from 'features/AnalysisProposal/components/HeadRight'
import SectionGroup from 'features/AnalysisProposal/components/SectionGroup'
import EditContract from 'features/EditContract'
import { ga } from '../../../../services/firebase'
import UpdateContractRegistrationDataConfirmation from 'features/UpdateContractRegistrationDataConfirmation'

class Juridical extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalContent: null,
      modalTitle: '',
      showWideModal: false,
      shouldBlock: true,
      contractNamesByType: {
        TYPE_RENTAL: 'Contrato de locação',
        TYPE_SALE: 'Contrato de venda',
        TYPE_ACQUISITION: 'Contrato de captação',
      },
    }
  }

  componentDidMount() {
    const { current, fetchReadyContract, fetchReadyContractVariables, isAgent } =
      this.props

    const contractFile = deepKey(current, 'proposal.contract.unsignedFile')

    if (contractFile && contractFile.id) {
      fetchReadyContract(contractFile.id)
    }

    if (current?.proposal?.id) {
      fetchReadyContractVariables(current?.proposal?.id)
    }

    if (!isAgent) {
      return this.setState({ shouldBlock: false })
    }

    const allButtons = document.querySelectorAll('#form-blocked button')

    for (let i = 0; i < allButtons.length; i++) {
      const currentButton = allButtons[i]

      if (currentButton.className.includes('btnContract')) {
        continue
      }

      const clone = currentButton.cloneNode(true)
      const parent = currentButton.parentNode

      parent.appendChild(clone)
      currentButton.remove()
    }
  }

  closeModal() {
    this.setState({
      modalContent: null,
      modalOpen: false,
      showWideModal: false,
      modalTitle: '',
    })
  }

  render() {
    const { contractNamesByType } = this.state
    const { activeCompany, current, isAgent = false } = this.props
    const { proposal, requirements } = current
    const { status } = ProcessTask(requirements, 'REQUIREMENT_JURIDICAL')
    const insuranceStatus =
      proposal?.guarantee?.code === Constants.GUARANTEE.GUARANTOR ||
      ProcessTask(requirements, 'REQUIREMENT_GUARANTEE').status

    const contractIsReady = proposal.type === 'TYPE_SALE' ? true : !!insuranceStatus

    const extraButtons = (active) => (
      <>
        <Button
          disabled={isAgent}
          onClick={() => {
            ga.logEvent('options_menu', { custom_value: 'edit-contract' })
            this.handleEditContrat.bind(this)()
          }}
          isBare={!active}
          isFullWidth={!active}
          isMedium={!active}
        >
          <Icon name='fas fa-edit' />
          Editar Contrato
        </Button>
        <Button
          disabled={isAgent}
          onClick={this.handleUpdateRegistrationData.bind(this)}
          isBare={!active}
          isFullWidth={!active}
          isMedium={!active}
        >
          <Icon name='fas fa-repeat-alt' />
          Gerar Contrato Atualizado
        </Button>
        <Button
          custom='btnContract'
          disabled={!contractIsReady}
          onClick={() => {
            ga.logEvent('options_menu', { custom_value: 'download-contract' })
            this.downloadFile.bind(this)()
          }}
          isBare={!active}
          isFullWidth={!active}
          isMedium={!active}
        >
          <Icon name='fas fa-eye' />
          Contrato original
        </Button>
      </>
    )

    const negotiation = deepKey(this.props.current, 'proposal.negotiation')
    const guarantee = deepKey(this.props.current, 'proposal.guarantee')
    const currentContractId = deepKey(
      this.props.current,
      'proposal.contract.contract_model_id',
    )
    const processId = deepKey(this.props.current, 'id')

    let contracts = []

    if (proposal.type !== 'TYPE_ACQUISITION') {
      contracts = (this.props.contractItems || []).filter((item) =>
        this.props.current?.proposal.type === 'TYPE_RENTAL'
          ? item.type === 'TYPE_RENTAL' && item.guarantee?.code === guarantee?.code
          : item.type === 'TYPE_SALE',
      )
    } else {
      contracts = (this.props.contractItems || []).filter(
        (item) => item.type === 'TYPE_ACQUISITION',
      )
    }

    const items = [
      {
        contain: (
          <Contract
            contracts={contracts}
            currentModelId={currentContractId}
            guarantee={guarantee}
            isClosed={status}
            source={
              (this.props.current?.proposal?.contract?.unsignedFile || {}).source
            }
            onSelect={this.selectContract.bind(this)}
          />
        ),
        icon: 'fa-file-signature',
        open: false,
        title:
          current.proposal.type in contractNamesByType &&
          contractNamesByType[current.proposal.type],
        orderNo: 1,
      },
      {
        contain: (
          <Text>
            <span style={{ whiteSpace: 'pre-line' }}>{negotiation}</span>
          </Text>
        ),
        icon: 'fa-handshake',
        title: 'Negociação',
        orderNo: 0,
        open: true,
      },
    ]
      .filter((item) => !!item)
      .sort((bf, af) => bf.orderNo - af.orderNo)

    const { modalContent, modalOpen, modalTitle, shouldBlock, showWideModal } =
      this.state

    const disabledIf = [6]

    return shouldBlock ? (
      <>
        <form id='form-blocked'>
          <fieldset>
            <div ref={(element) => (this.componentRef = element)}>
              <div className='print-wrap'>
                <SectionWrapper
                  customHeader={true}
                  header={<p>Jurídico</p>}
                  headerRight={HeadRight({ extraButtons, disabledIf })}
                >
                  <SectionGroup items={items} />
                  {activeCompany && (
                    <SectionWrapper header='Gerenciar etapa'>
                      <ToggleRequirement
                        processId={processId}
                        onSuccess={this.closeRequirementSuccess.bind(this)}
                        requirement='juridical'
                        requirementStatus={status}
                        companyId={activeCompany.id}
                      />
                    </SectionWrapper>
                  )}
                </SectionWrapper>
              </div>
            </div>
            <Modal
              title={modalTitle}
              isOpen={modalOpen}
              isWide={showWideModal}
              onClose={this.closeModal.bind(this)}
              backgroundContentStyle={{ display: 'block' }}
            >
              {modalContent}
            </Modal>
          </fieldset>
        </form>
      </>
    ) : (
      <>
        <div ref={(element) => (this.componentRef = element)}>
          <div className='print-wrap'>
            <SectionWrapper
              customHeader={true}
              header={<p>Jurídico</p>}
              headerRight={HeadRight({ extraButtons, disabledIf })}
            >
              <SectionGroup items={items} />
              {activeCompany ? (
                <SectionWrapper header='Gerenciar etapa'>
                  <ToggleRequirement
                    processId={processId}
                    onSuccess={this.closeRequirementSuccess.bind(this)}
                    requirement='juridical'
                    requirementStatus={status}
                    companyId={activeCompany.id}
                  />
                </SectionWrapper>
              ) : null}
            </SectionWrapper>
          </div>
        </div>
        <Modal
          title={modalTitle}
          isOpen={modalOpen}
          isWide={showWideModal}
          onClose={this.closeModal.bind(this)}
          backgroundContentStyle={{ display: 'block' }}
        >
          {modalContent}
        </Modal>
      </>
    )
  }

  selectContract(modelId) {
    const oldId = deepKey(
      this.props.current,
      'proposal.contract.guarantee_contract_id',
    )

    if (modelId == '' || oldId == modelId) return

    this.setState({
      modalOpen: true,
      modalTitle: 'Mudança do modelo de contrato',
      showWideModal: false,
      modalContent: (
        <div>
          <p>A troca de modelo apagará qualquer edição feita no contrato atual.</p>
          <p>Tem certeza que deseja continuar?</p>

          <div className='mt-2 columns'>
            <div className='column'>
              <Button isFlat isSecondary onClick={this.closeModal.bind(this)}>
                Não, quero manter esse contrato.
              </Button>
            </div>
            <div className='column text-right'>
              <Button
                isFlat
                hasThemeColor
                onClick={this.updateContract.bind(this, modelId)}
              >
                Sim, quero continuar.
              </Button>
            </div>
          </div>
        </div>
      ),
    })
  }

  downloadFile() {
    if (!this.props.contract) return
    const { current } = this.props

    const element = document.createElement('A')

    element.target = '_blank'
    element.href = this.props.contract.source
    element.download =
      'contrato-de-locacao-' + current.proposal.code.replace(' ', '') + '.pdf'
    element.click()
  }

  updateContract(modelId) {
    this.closeModal()
    this.props.fetchContract(this.props.match.params.processId, modelId).then(() => {
      this.props.fetch(this.props.match.params.processId)
    })
  }

  onSuccessHandler({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Cláusula adicional atualizada com sucesso',
      })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao salvar a cláusula adicional',
        status: 'danger',
      })
    }
  }

  closeRequirementSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Etapa alterada com sucesso' })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar alterar a etapa',
        status: 'danger',
      })
    }
  }

  onUpdateContractSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({ message: 'Contrato editado com sucesso' })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar editar o contrato',
        status: 'danger',
      })
    }
  }

  onUpdateRegistrationDataSuccess({ isSuccess, message }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Dados cadastrais no contrato atualizado com sucesso',
      })
      this.props.fetch(this.props.current.id)
    } else {
      this.props.notification({
        message: message,
        status: 'danger',
      })
    }
  }

  handleEditContrat() {
    const contents = this.props.current?.proposal?.contract?.contents || ''
    const processId = this.props.current?.id

    this.setState({
      modalOpen: true,
      modalTitle: 'Editar Contrato',
      modalContent: (
        <EditContract
          tokenOptions={this.props.contractReadyVariables}
          template={contents}
          processId={processId}
          onSuccess={this.onUpdateContractSuccess.bind(this)}
          closeModal={() => this.closeModal}
        />
      ),
      showWideModal: true,
    })
  }

  handleUpdateRegistrationData() {
    const { current } = this.props
    const processId = this.props.current?.id

    if (current?.proposal?.contract?.manually_updated_at) {
      this.setState({
        modalOpen: true,
        modalTitle: 'Deseja realmente gerar o Contrato Atualizado?',
        modalContent: (
          <UpdateContractRegistrationDataConfirmation
            {...this.props}
            manually_updated_at={current?.proposal?.contract?.manually_updated_at}
            processId={processId}
            onClose={this.closeModal.bind(this)}
            onSuccess={this.onUpdateRegistrationDataSuccess.bind(this)}
          />
        ),
        showWideModal: false,
      })
    } else {
      this.props.loading()
      request
        .post(`{company}/process/${processId}/generate-contract`)
        .then(() => {
          this.props.unloading()
          this.onUpdateRegistrationDataSuccess({ isSuccess: true })
        })
        .catch((error) => {
          this.props.unloading()
          this.onUpdateRegistrationDataSuccess({
            isSuccess: false,
            message:
              error.message ||
              'Não foi possível concluir a operação. Tente novamente em alguns instantes.',
          })
        })
    }
  }
}

export default withRedux(
  (store) => ({
    current: store.processDetails.current,
    contract: store.processDetails.contract,
    contractReadyVariables: store.processDetails.contractReadyVariables,
    contractItems: store.settings.contractItems,
  }),
  { ...actions, ...mainActions, ...commons },
)(Juridical)
