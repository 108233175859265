import _Email from './Email'
import _Password from './Password'
import _Text from './Text'
import _TextArea from './TextArea'
import _CEP from './CEP'
import _CNPJ from './CNPJ'
import _Select from './Select'
import _Date from './Date'
import _Time from './Time'
import _Phone from './Phone'
import _Search from './Search'
import _Switch from './Switch'
import _File from './File'
import _Checkbox from './Checkbox'
import _TokenSelector from './TokenSelector'

import _getValues from './utils/getValues'
import _invalidFields from './utils/invalidFields'
import _valuesFormatter from './utils/valuesFormatter'

export const FormFieldEmail = _Email
export const FormFieldPassword = _Password
export const FormFieldText = _Text
export const FormFieldTextArea = _TextArea
export const FormFieldCEP = _CEP
export const FormFieldCNPJ = _CNPJ
export const FormFieldSelect = _Select
export const FormFieldDate = _Date
export const FormFieldTime = _Time
export const FormFieldPhone = _Phone
export const FormFieldSearch = _Search
export const FormFieldSwitch = _Switch
export const FormFieldFile = _File
export const FormFieldCheckbox = _Checkbox
export const FormFieldTokenSelector = _TokenSelector

export const getValues = _getValues
export const invalidFields = _invalidFields
export const valuesFormatter = _valuesFormatter
