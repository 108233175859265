import React, { useState } from 'react'
import styled from 'styled-components'
import Button from 'components/lib/Button'
import Dropdown from 'components/lib/Dropdown'
import Header from 'components/lib/Header'
import Icon from 'components/lib/Icon'
import BuyCreditAnalysisModal from './BuyCreditAnalysisModal'
import UploadPaymentReceiptCreditAnalysisModal from './UploadPaymentReceiptCreditAnalysisModal'

// Styled components
const Container = styled.div`
  color: #333;
`

const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const Description = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 0;
`

const Divider = styled.hr`
  margin-top: 10px;
  width: 100%;
  border: 0;
  opacity: 0.5;
`

const BalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
`

const BalanceText = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`

const BalanceValue = styled.p`
  font-size: 1.5rem;
  margin: 0.5rem 0 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const IconButton = styled.button`
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 1rem;
`

const DropdownItem = styled(Dropdown.Item)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  cursor: pointer;
  color: hsl(0deg, 0%, 29%);
`

// Main React component
const CreditAnalysisHomeHeader = ({
  integrationData,
  payload,
  requestState,
  createProposalSolicitation,
  createSolicitation,
}) => {
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState(false)
  const [showUploadPaymentReceiptModal, setShowUploadPaymentReceiptModal] =
    useState(false)

  return (
    <>
      <Container>
        <Title>FC Análise</Title>
        <Description>
          Realize uma análise de crédito detalhada feita pela FC Análise, com
          informações exclusivas voltadas para locação.
        </Description>
        <Divider />
        <Header isTiny subtitle={<Divider />}>
          <BalanceContainer>
            <BalanceText>Saldo disponível</BalanceText>
            <BalanceValue className='has-content-theme-color'>
              {integrationData.creditAnalysis.creditAnalysisTickets} créditos
            </BalanceValue>
          </BalanceContainer>
          <ButtonsContainer>
            {payload.id ? (
              <Button
                hasThemeColor
                disabled={requestState === 'processing'}
                onClick={createProposalSolicitation}
              >
                Nova Análise
              </Button>
            ) : (
              <Button
                hasThemeColor
                disabled={requestState === 'processing'}
                onClick={createSolicitation}
              >
                Nova Análise
              </Button>
            )}
            <Button
              hasThemeColor
              disabled={requestState === 'processing'}
              onClick={() => setShowBuyCreditsModal(true)}
            >
              Adicionar créditos
            </Button>
            <Dropdown
              isRight
              keepOpen
              trigger={
                <IconButton className='has-content-theme-color'>
                  <Icon name='fa fa-ellipsis-v' />
                </IconButton>
              }
            >
              <DropdownItem
                onClick={() => {
                  setShowUploadPaymentReceiptModal(true)
                }}
              >
                <Icon name='fa fa-upload' />
                Enviar comprovante
              </DropdownItem>
            </Dropdown>
          </ButtonsContainer>
        </Header>
      </Container>
      <BuyCreditAnalysisModal
        isOpen={showBuyCreditsModal}
        onClose={() => setShowBuyCreditsModal(false)}
      />
      <UploadPaymentReceiptCreditAnalysisModal
        isOpen={showUploadPaymentReceiptModal}
        onClose={() => setShowUploadPaymentReceiptModal(false)}
      />
    </>
  )
}

export default CreditAnalysisHomeHeader
