import React, { useEffect, useState } from 'react'

import integrationsConfiax from '../../'

import NeedInformationModal from './components/NeedInformationModal'

import AccountInformation from './components/AccountInformation'
import MissingFieldsModal from './components/MissingFieldsModal'
import Analysis from './components/Analysis'
import Loading from './components/Loading'

const genderMapToConfiax = new Map([
  ['M', 'MASCULINO'],
  ['F', 'FEMININO'],
])

const Confiax = ({ company, proposal, props }) => {
  const companyId = company.id

  const [isLoading, setIsLoading] = useState(true)

  const [showMissingFieldsModal, setShowMissingFieldsModal] = useState(false)
  const [missingFields, setMissingFields] = useState([])

  const [showNeedInformationModal, setNeedInformationModal] = useState(false)
  const [propertyData, setPropertyData] = useState({})
  const [personData, setPersonData] = useState({})

  const [confiaxAccount, setConfiaxAccount] = useState({})
  const [appData, setAppData] = useState([])

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const { success, data } = await integrationsConfiax.confiax({
        companyId,
        payload: {
          action: 'CHECK_ACCOUNT',
          company,
        },
      })

      if (success) {
        setConfiaxAccount(data.app.response.data)
        getQuotes()
      }
    } catch {
      props.notification({
        message: 'Erro ao checar sua conta na Rede Seguros',
        status: 'danger',
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const getQuotes = async () => {
    try {
      setIsLoading(true)

      const { data } = await integrationsConfiax.confiax({
        companyId,
        payload: {
          action: 'GET_QUOTES',
          company,
          proposal,
        },
      })

      setAppData(data.app.response.data)
      setIsLoading(false)
    } catch {
      props.notification({
        message: 'Erro ao procurar cotações na Rede Seguros',
        status: 'danger',
      })
    }
  }

  const downloadPolicy = async (bondInsuranceId) => {
    try {
      setIsLoading(true)

      const { data } = await integrationsConfiax.confiax({
        companyId,
        payload: {
          action: 'DOWNLOAD_POLICY',
          bondInsuranceId,
        },
      })

      if (data?.app?.response?.data?.policy) {
        window.open(data.app.response.data.policy, '_blank')
      } else if (data?.app?.response?.data?.message) {
        props.notification({
          message: data?.app?.response?.data?.message,
          status: 'danger',
        })
      }

      setIsLoading(false)
    } catch {
      props.notification({
        message: 'Erro ao baixar apólice na Rede Seguros',
        status: 'danger',
      })
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (appData.length > 0) {
    return <Analysis quote={appData[0]} downloadPolicy={downloadPolicy} notification={props.notification} />
  }

  const validateSystemData = async () => {
    let person

    const mainClient = proposal.clients[0]
    const mainProperty = proposal.properties[0]

    const isJuridical = !!mainClient.cnpj

    const property = {
      validity: proposal.validity_months,
      rent_value: proposal.rent_value,
      condominium_fee: mainProperty.condo_fee,
      zip_code: mainProperty.address?.zip_code,
      city: mainProperty.address?.city,
      district: mainProperty.address?.neighborhood,
      complement: mainProperty.address?.additional_address || '',
      state: mainProperty.address?.state,
      street: mainProperty.address?.address,
      number: mainProperty.address?.number,
      taxes_value: mainProperty.iptu,
    }

    if (isJuridical) {
      person = {
        contact_phone: mainClient.phone,
        email: mainClient.email,
        document: mainClient.cnpj,
        company: mainClient.corporate_name,
        contact_name: mainClient.name,
        zip_code: mainClient.trading_address?.zip_code,
        city: mainClient.trading_address?.city,
        district: mainClient.trading_address?.neighborhood,
        complement: mainClient.trading_address?.additional_address || '',
        state: mainClient.trading_address?.state,
        street: mainClient.trading_address?.address,
        number: mainClient.trading_address?.number,
        gender: genderMapToConfiax.get(mainClient?.gender) || 'UNDEFINED',
        mother_name: mainClient.mother_name,
      }
    } else {
      const maritalFormatted = {
        MARITAL_STATUS_SINGLE: 'SOLTEIRO',
        MARITAL_STATUS_MARRIED: 'CASADO',
        MARITAL_STATUS_DIVORCED: 'SEPARADO',
        MARITAL_STATUS_STABLE_UNION: 'UNIAO ESTAVEL',
        MARITAL_STATUS_WIDOWED: 'VIUVO',
      }

      person = {
        main_tenant_nationality: mainClient.nationality,
        main_tenant_profession: mainClient.occupation,
        main_tenant_email: mainClient.email,
        main_tenant_cpf: mainClient.cpf,
        main_tenant_name: mainClient.name,
        main_tenant_birth_date: mainClient.birth_date,
        main_tenant_phone: mainClient.phone,
        main_tenant_marital_status:
          mainClient.marital_status &&
          maritalFormatted[mainClient.marital_status.code],
        main_tenant_gender:
          genderMapToConfiax.get(mainClient?.gender) || 'UNDEFINED',
        main_tenant_mother_name: mainClient.mother_name,
      }
    }

    try {
      const payload = {
        action: 'CHECK_DATA',
        insuranceData: {
          analysis_assurance: 'SIMPLE',
          purpose: proposal.goal?.code
            ? proposal.goal.code === 'COMERCIAL_GOAL'
              ? 'COMMERCIAL'
              : 'RESIDENTIAL'
            : null,
          tenant_type: isJuridical ? 'LEGAL' : 'NATURAL',

          property: property,

          [isJuridical ? 'legal_tenant' : 'natural_tenant']: {
            ...person,
          },
        },
        company,
        proposal,
      }

      const { data } = await integrationsConfiax.confiax({
        companyId: company.id,
        payload,
      })

      if (!data.app.response.success) {
        const excludedFields = {
          'property.reason': true,
          'property.total_amount': true,
          'natural_tenant.resident': true,
          'natural_tenant.main_tenant_gender': false,
          'natural_tenant.main_tenant_mother_name': false,
          'natural_tenant.employment_relationship': true,
          'natural_tenant.main_tenant_politically_exposed_person': true,
          'natural_tenant.partner_rent_composition': true,
          'natural_tenant.include_sympathise_tenants': true,
          'legal_tenant.phone': true,
          'legal_tenant.foundation_date': true,
          'legal_tenant.share_capital': true,
          'legal_tenant.last_contractual_alteration_date': true,
          'legal_tenant.paid_in_capital': true,
          'legal_tenant.anual_revenue': true,
          'legal_tenant.partners': true,
          'legal_tenant.partners.*.document': true,
          'legal_tenant.partners.*.name': true,
          'legal_tenant.partners.*.percent': true,
          'legal_tenant.partners.*.date_of_entry': true,
        }

        const missingFields = data.app.response.data.errors.filter((error) => {
          const isInExcludedList = excludedFields[error.field]

          // eslint-disable-next-line no-extra-boolean-cast
          const notExcluded = !Boolean(isInExcludedList)

          return notExcluded
        })

        if (missingFields.length > 0) {
          return {
            success: false,
            data: {
              errors: missingFields,
            },
          }
        }

        return {
          success: true,
          data: {
            property,
            person,
          },
        }
      }

      return {
        success: true,
        data: {
          property,
          person,
        },
      }
    } catch (error) {
      props.notification({
        message: 'Erro ao validar os dados na Rede Seguros',
        status: 'danger',
      })

      return {
        success: false,
        data: {
          errors: [],
        },
      }
    }
  }

  const handleOpenModal = async () => {
    const { success, data } = await validateSystemData()

    if (!success) {
      if (data?.errors.length > 0) {
        setMissingFields(data.errors)

        setShowMissingFieldsModal(true)
      }

      return
    }

    setPropertyData(data.property)
    setPersonData(data.person)

    setNeedInformationModal(true)
  }

  return (
    <>
      {showMissingFieldsModal && (
        <MissingFieldsModal
          missingFields={missingFields}
          closeModal={() => setShowMissingFieldsModal(false)}
        />
      )}

      {showNeedInformationModal && (
        <NeedInformationModal
          company={company}
          proposal={proposal}
          personData={personData}
          propertyData={propertyData}
          notification={props.notification}
          closeModal={() => setNeedInformationModal(false)}
          onSuccess={() => fetchData()}
        />
      )}

      <AccountInformation
        confiaxAccount={confiaxAccount}
        onCreateAnalysis={handleOpenModal}
      />
    </>
  )
}

export default Confiax
