import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'

import Field from 'components/lib/FormFields/components/Field'
import Control from 'components/lib/FormFields/components/Control'
import Icon from 'components/lib/Icon'
import Text from 'components/lib/Text'
import Modal from 'components/lib/Modal'
import CreateProperty from 'features/CreateProperty'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import AddButton from './components/AddButton'

import { request } from 'core/constants'

const PropertySelection = ({
  defaultValue = {},
  isDisabled = false,
  showAddition = false,
  onAdditionSelected,
  handleFieldChanges,
  onSelect,
  notification,
  errorMessage,
  isValid,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState(defaultValue.properties || [])
  const [isWorking, setIsWorking] = useState(isDisabled)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const asyncRef = useRef(null)

  const promiseOptions = useCallback((inputValue) => {
    return new Promise((resolve, reject) => {
      setIsWorking(true)
      request
        .get(`{company}/property/search?q=${inputValue}`)
        .then((response) => resolve(response.data))
        .catch(() => reject())
        .finally(() => setIsWorking(false))
    })
  }, [])

  const debouncePromiseOptions = AwesomeDebouncePromise(promiseOptions, 400)

  useEffect(() => {
    if (defaultValue.properties && defaultValue.properties.length > 0) {
      const defaultOption = defaultValue.properties[0]

      handleFieldChanges('property_ids', [defaultOption.id], {
        isValid: [defaultOption.id].length > 0,
        value: [defaultOption.id],
      })
    }
  }, [])

  const onAdded = (result) => {
    if (result.isSuccess) {
      setModalIsOpen(false)
      setOptions(result.data)
      onChangeAsyncSelect(result.data)
    } else {
      notification({
        message: 'Não foi possível adicionar esse imóvel',
        status: 'danger',
      })
    }
  }

  const setNewOptions = (options) => {
    setOptions(options)
    setInputValue('')

    if (onSelect) onSelect(options)

    handleFieldChanges('property_ids', [options.id], {
      isValid: [options.id].length > 0,
      value: [options.id],
    })
  }

  const importProperty = (options) => {
    setNewOptions(options)

    if (options.source && options.id) {
      setIsWorking(true)
      request
        .get(`{company}/property/import/${options.source}?id=${options.id}`)
        .then((response) => {
          setNewOptions(response.data)
          setIsWorking(false)
        })
    }
  }

  const onChangeAsyncSelect = (options) => {
    if (options.source === 'izee') {
      setNewOptions(options)
    } else {
      importProperty(options)
    }
  }

  const handleInputChange = (value) => {
    setInputValue(value)

    return value
  }

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <Icon name='fas fa-home' />
    </components.DropdownIndicator>
  )

  const Option = (props) => {
    const address = props.data.address || {}
    const data = props.data || {}

    return (
      <components.Option {...props}>
        <span style={{ fontSize: 14, fontWeight: 700, color: '#4a4a4a' }}>
          {data.name || data.code}
          {data.name && data.code ? ` (${data.code})` : ''}
        </span>
        <br />
        {address.address ? (
          <span style={{ fontSize: 12 }}>
            {address.address}, {address.neighborhood} - {address.city} /{' '}
            {address.state}
          </span>
        ) : (
          'Sem endereço'
        )}
      </components.Option>
    )
  }

  const SingleValue = (props) => {
    if (props.data.source && props.data.source !== 'izee') {
      return <Text>Importando do {props.data.source}...</Text>
    }

    const address = props.data.address || {}

    return (
      <components.SingleValue {...props}>
        {address.address ? (
          <span style={{ fontSize: 12 }}>
            {address.address}, {address.neighborhood} - {address.city} /{' '}
            {address.state}
          </span>
        ) : (
          'Sem endereço'
        )}
      </components.SingleValue>
    )
  }

  const LoadingIndicator = () => null

  return (
    <>
      <Field label='Imóvel'>
        <Control>
          <div className='react-select-wrapper' style={{ display: 'flex' }}>
            <AsyncSelect
              styles={
                !isValid && {
                  control: (provided) => ({
                    ...provided,
                    borderColor: '#ff0000',
                  }),
                }
              }
              defaultOptions
              ref={asyncRef}
              classNamePrefix='react-select'
              id='property-selection'
              defaultValue={[]}
              name='property'
              isDisabled={isDisabled}
              isLoading={isWorking}
              value={options}
              loadingMessage={() => 'Pesquisando por imóveis...'}
              noOptionsMessage={() => <span>Nenhum imóvel encontrado</span>}
              placeholder='Selecionar imóvel'
              menuPosition='fixed'
              maxMenuHeight={168}
              minMenuHeight={100}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              onChange={onChangeAsyncSelect}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              components={{
                SingleValue,
                DropdownIndicator,
                LoadingIndicator,
                Option,
              }}
              loadOptions={debouncePromiseOptions}
            />
            {showAddition && !isDisabled && (
              <AddButton
                onClick={() => {
                  if (onAdditionSelected) {
                    onAdditionSelected()
                    setOptions([])
                  } else {
                    setModalIsOpen(true)
                  }
                }}
              />
            )}
          </div>
        </Control>
        {!isValid && <p className='help is-danger'>{errorMessage}</p>}
      </Field>
      <Modal
        title='Criar novo Imóvel'
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        backgroundContentStyle={{ display: 'block' }}
      >
        <CreateProperty onSuccess={onAdded} hidePropertyOwnerSelectionControl />
      </Modal>
    </>
  )
}

PropertySelection.propTypes = {
  defaultValue: PropTypes.object,
  isDisabled: PropTypes.bool,
  showAddition: PropTypes.bool,
  onAdditionSelected: PropTypes.func,
  handleFieldChanges: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  notification: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
}

export default PropertySelection
