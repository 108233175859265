import React from 'react'

// Components
import { FormFieldCheckbox } from 'components/lib/FormFields'
import Grid from 'components/lib/Grid'
import Space from 'components/lib/Space'
import Header from 'components/lib/Header'
import Button from 'components/lib/Button'

import integrations from '../../'
import { baseURL } from 'core/constants'
import { request } from 'core/constants'

import { fb } from '../../../firebase'

import GuarantorSelection from 'features/components/fieldsGroup/GuarantorSelection'
import ClientSelection from 'features/components/fieldsGroup/ClientSelection'

export default class EditSolicitation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clients: [],
      guarantors: [],
      applicantList: [],
      applicantsSelected: [],
      applicantsIncluded: [],
      isLoading: false,
      applicantValid: true,
      applicantAnalyzed: false,
      solicitation: null,
      message: '',
      applicantsDuplicated: null,
    }
  }

  componentDidMount() {
    this.getSolicitation()
  }

  getSolicitation() {
    const { companyId, solicitationId } = this.props

    this.setState({ isLoading: true, message: 'Buscando Solicitação...' })

    const onError = (message, error) => {
      console.log(message, error)

      this.props.notification({
        message:
          error.message || 'Não foi possível carregar os dados, tente novamente',
        status: 'danger',
      })

      this.props.setModalState(false)
    }

    integrations
      .apps({
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'DETAILS',
          companyId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (data.app.response.success) {
          this.setState({ solicitation: data.app.response.data })

          fb.fichacertaSolicitationById(solicitationId, ({ success, data }) => {
            try {
              if (success && data) {
                this.setState({
                  applicantsIncluded: Object.values(data.applicants),
                })
              }
            } catch (error) {
              onError(
                'Error: editSolicitation, function fichacertaSolicitationById',
                error,
              )
            }
          })

          this.setState({ isLoading: false, message: '' })
        } else {
          console.log('Fichacerta detalhes da solicitação falhou', success, data)

          this.props.notification({
            message: data.app.response.message || 'Falha ao receber detalhes',
            status: 'danger',
          })

          this.props.setModalState(false)
        }
      })
      .catch((error) => {
        onError('Error: editSolicitation, get DETAILS from ficha certa', error)
      })
  }

  formatName(item) {
    if (item.applicantType) {
      // nao incluidos
      switch (item.applicantType) {
        case 'OUTROS':
          return item.corporate_name || item.applicant.corporate_name + ' - Empresa'

        case 'INQUILINO':
          return item.name || item.applicant.name + ' - Cliente'

        case 'FIADOR':
          return item.name || item.applicant.name + ' - Fiador'

        case 'CONJUGE_INQUILINO':
          return item.name || item.applicant.name + ' - Conjuge do(a) cliente'

        case 'CONJUGE_FIADOR':
          return item.name || item.applicant.name + ' - Conjuge do Fiador'
      }
    }
  }

  updateApplicantsSelected(list) {
    const counts = {}

    const items = this.state.applicantsIncluded.concat(list.map(i => i.applicant))
    
    items.forEach(( applicant ) => {
      const identifiers = [applicant.cpf, applicant.cnpj].filter(
        (id) => id && id.trim() !== '',
      )

      identifiers.forEach((id) => {
        counts[id] = (counts[id] || 0) + 1
      })
    })

    // Filter identifiers with more than one occurrence
    const duplicates = Object.entries(counts).filter(([, count]) => count > 1)

    let duplicated = null

    if (duplicates.length > 0) {
      const duplicatedStr = duplicates
        .map(([id, count]) => `${id} (${count} vezes)`)
        .join(', ')

      duplicated = `Pretendentes duplicados (cpf/cnpj): ${duplicatedStr}`
    }

    this.setState({
      applicantsSelected: list,
      applicantsDuplicated: duplicated,
    })
  }

  async formatapplicantList() {
    //cria lista de pretendentes com as entidades selecionadas
    let { clients, guarantors } = this.state
    let applicants = []

    if (clients?.length > 0) {
      const clientRequests = clients.map(async (item) => {
        const client = (await request.get(`{company}/client/${item.id}`)).data

        if (client.cnpj) {
          applicants.push({
            applicant: client,
            key: client.email + client.id,
            applicantType: 'OUTROS',
          })
        } else {
          applicants.push({
            applicant: client,
            key: client.email + client.id,
            applicantType: 'INQUILINO',
          })
          if (client.spouse_email) {
            applicants.push({
              applicant: client,
              key: client.spouse_email + client.id,
              applicantType: 'CONJUGE_INQUILINO',
            })
          }
        }
      })

      await Promise.all(clientRequests)
    }

    if (guarantors?.length > 0) {
      const guarantorRequests = guarantors.map(async (item) => {
        const guarantor = (await request.get(`{company}/guarantor/${item.id}`)).data

        if (guarantor.cnpj) {
          applicants.push({
            applicant: guarantor,
            key: guarantor.email + guarantor.id,
            applicantType: 'OUTROS',
          })
        } else {
          applicants.push({
            applicant: guarantor,
            key: guarantor.email + guarantor.id,
            applicantType: 'FIADOR',
          })
          if (guarantor.spouse_email) {
            applicants.push({
              applicant: guarantor,
              key: guarantor.spouse_email + guarantor.id,
              applicantType: 'CONJUGE_FIADOR',
            })
          }
        }
      })

      await Promise.all(guarantorRequests)
    }

    this.setState({ applicantList: applicants })
    this.updateApplicantsSelected(applicants)
  }

  selectedClientsHandler(clients) {
    this.setState({ clients }, () => {
      this.formatapplicantList()
    })
  }

  selectedGuarantorsHandler(guarantors) {
    this.setState({ guarantors }, () => {
      this.formatapplicantList()
    })
  }

  handleFieldChanges() {
    this.setState({ applicantValid: true, applicantAnalyzed: false })
  }

  render() {
    const insufficientCredits = this.props.creditAnalysisTickets <= 0
    const {
      solicitation,
      applicantsIncluded,
      applicantList,
      applicantsSelected,
      isLoading,
      message,
      clients,
      guarantors,
      applicantValid,
      applicantAnalyzed,
      applicantsDuplicated,
    } = this.state

    return (
      <React.Fragment>
        {solicitation && !isLoading ? (
          <>
            <div>
              <Header
                isTiny
                isUppercase
                subtitle={<hr style={{ marginTop: '0.5rem' }} />}
              >
                Locação
                <Button
                  isSmall
                  disabled={isLoading}
                  onClick={() => this.updateSolicitation()}
                >
                  Atualizar informações do imovel
                </Button>
              </Header>

              <Grid>
                {solicitation?.locacao && (
                  <Grid.Col>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Código do imóvel</b>: {solicitation.locacao?.codigo_imovel}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Proprietário do imóvel</b>:{' '}
                      {solicitation.locacao?.proprietario}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Tipo de imovel</b>: {solicitation.locacao?.tipo_imovel}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Valor do aluguel</b>: {solicitation.locacao?.aluguel}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Valor do condomínio</b>: {solicitation.locacao?.condominio}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Valor do IPTU</b>: {solicitation.locacao?.iptu}
                    </p>
                  </Grid.Col>
                )}
                {solicitation?.locacao?.endereco?.[0] && (
                  <Grid.Col>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Endereço</b>: {solicitation.locacao.endereco[0].logradouro}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Número</b>: {solicitation.locacao.endereco[0].numero}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Complemento</b>:{' '}
                      {solicitation.locacao.endereco[0].complemento}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Bairro</b>: {solicitation.locacao.endereco[0].bairro}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Cidade</b>: {solicitation.locacao.endereco[0].cidade}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Estado</b>: {solicitation.locacao.endereco[0].uf}
                    </p>
                    <p style={{ marginBottom: '5px' }}>
                      <b>Cep</b>: {solicitation.locacao.endereco[0].cep}
                    </p>
                  </Grid.Col>
                )}
              </Grid>
            </div>
            <div className='mt-3'>
              <Header
                isTiny
                isUppercase
                subtitle={<hr style={{ marginTop: '0.5rem' }} />}
              >
                Pretendentes incluídos
              </Header>

              <Grid>
                <Grid.Col>
                  {applicantsIncluded && (
                    <div>
                      {applicantsIncluded.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className='mb-2'
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {this.formatName(item)}
                            <Button
                              style={{ marginLeft: '10px' }}
                              isSmall
                              disabled={isLoading}
                              onClick={() => this.updateApplicant(item)}
                            >
                              Atualizar Pretendente
                            </Button>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </Grid.Col>
              </Grid>
            </div>

            <div className='mt-3'>
              <Header
                isTiny
                isUppercase
                subtitle={<hr style={{ marginTop: '0.5rem' }} />}
              >
                Incluir Pretendentes
              </Header>

              <Grid>
                <Grid.Col>
                  <div className='text-right mb-2'>
                    Créditos restantes: {this.props.creditAnalysisTickets}
                  </div>
                  {!applicantValid && !applicantAnalyzed && (
                    <div className='mb-4'>
                      <p>Informe os dados do pretendente para incluir na análise:</p>
                      <ul>
                        <li>
                          <b>- Nome/Razão Social</b>
                        </li>
                        <li>
                          <b>- CPF/CNPJ</b>
                        </li>
                        <li>
                          <b>- Renda</b>
                        </li>
                        <li>
                          <b>- Fonte de Renda</b>
                        </li>
                      </ul>
                    </div>
                  )}
                  {applicantsDuplicated && (
                    <div className='mb-4'>
                      <p>{applicantsDuplicated}</p>
                    </div>
                  )}
                  {insufficientCredits && (
                    <div className='mb-4'>
                      <p>
                        <b>
                          Quantidade de créditos insuficiente para incluir novos
                          pretendentes.
                        </b>
                      </p>
                    </div>
                  )}
                  {applicantAnalyzed && (
                    <div className='mb-4'>
                      <p>
                        <b>
                          Não é possivel adicionar um pretendente que ja está na
                          solicitação.
                        </b>
                      </p>
                    </div>
                  )}

                  <div className='create-solicitation-fc'>
                    <ClientSelection
                      {...this.props}
                      onSelect={this.selectedClientsHandler.bind(this)}
                      isValid={true}
                      clients={clients}
                      handleFieldChanges={this.handleFieldChanges.bind(this)}
                    />

                    <GuarantorSelection
                      {...this.props}
                      onSelect={this.selectedGuarantorsHandler.bind(this)}
                      isValid={true}
                      // showAddition={false}
                      guarantors={guarantors}
                      handleFieldChanges={this.handleFieldChanges.bind(this)}
                    />
                    {applicantList.map((item) => {
                      const isChecked = applicantsSelected.filter(
                        (applicant) => applicant.key === item.key,
                      ).length
                      return (
                        <FormFieldCheckbox
                          key={item.key}
                          name={this.formatName(item)}
                          isDisabled={this.props.formIsWorking}
                          // defaultChecked={isChecked}
                          checked={isChecked}
                          // onChange={this.handleCheckboxChanges.bind(this, item)}
                          onClick={() => this.handleCheckboxChanges(item, isChecked)}
                          description={this.formatName(item)}
                        />
                      )
                    })}
                  </div>

                  <div className='mb-2 mt-3'>
                    * Cada pretendente incuido irá consumir 1 credito.
                  </div>
                </Grid.Col>
              </Grid>

              <div className='text-right mt-3'>
                <Button
                  style={{ marginRight: '5px' }}
                  isSmall
                  disabled={
                    isLoading ||
                    !applicantsSelected.length ||
                    !applicantValid ||
                    insufficientCredits ||
                    applicantAnalyzed
                  }
                  onClick={() => this.addApplicants()}
                >
                  Incluir selecionados
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div style={{ height: '250px', marginTop: 100 }}>
              <div
                style={{ margin: '0 auto', padding: '45px 60px' }}
                className='empty-state-spinner'
              />
              <p className='text-center mt-2'>
                {' '}
                <strong>{message}</strong>
              </p>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  handleCheckboxChanges(item, value) {
    let list = this.state.applicantsSelected

    if (!value) {
      if (list.filter((listItem) => listItem.key === item.key).length) return
      list.push(item)
    } else {
      list = list.filter((listItem) => listItem.key !== item.key)
    }
    this.setState({
      applicantValid: true,
      applicantAnalyzed: false,
    })

    this.updateApplicantsSelected(list)
  }

  validateApplicants() {
    let applicants = this.state.applicantsSelected
    const { applicantsIncluded } = this.state

    let isValid = true

    let applicantAnalyzed = false

    applicants.map((item) => {
      if (item.applicantType == 'OUTROS') {
        if (!item.applicant.corporate_name || !item.applicant.cnpj) {
          isValid = false

          return
        }
        if (
          applicantsIncluded.find(
            (user) =>
              user.cnpj?.replace(/[^\d]+/g, '') ==
              item.applicant?.cnpj.replace(/[^\d]+/g, ''),
          )
        ) {
          applicantAnalyzed = true
          isValid = false

          return
        }
      }

      if (item.applicantType == 'INQUILINO' || item.applicantType == 'FIADOR') {
        if (
          !item.applicant.name ||
          !item.applicant.cpf ||
          !item.applicant.income_source
        ) {
          isValid = false

          return
        }
        if (
          applicantsIncluded.find(
            (user) =>
              user.cpf?.replace(/\./g, '').replace('-', '') ==
              item.applicant.cpf?.replace(/\./g, '').replace('-', ''),
          )
        ) {
          applicantAnalyzed = true
          isValid = false

          return
        }
      }

      if (
        item.applicantType == 'CONJUGE_INQUILINO' ||
        item.applicantType == 'CONJUGE_FIADOR'
      ) {
        if (
          !item.applicant.spouse_name ||
          !item.applicant.spouse_cpf ||
          !item.applicant.spouse_income_source
        ) {
          isValid = false

          return
        }
        if (
          applicantsIncluded.find(
            (user) =>
              user.cpf?.replace(/\./g, '').replace('-', '') ==
              item.applicant.spouse_cpf?.replace(/\./g, '').replace('-', ''),
          )
        ) {
          applicantAnalyzed = true
          isValid = false

          return
        }
      }
    })

    this.setState({ applicantValid: isValid, applicantAnalyzed: applicantAnalyzed })

    return isValid
  }

  addApplicants() {
    let isValid = this.validateApplicants()

    if (!isValid) {
      return
    }

    const { companyId, companyDocument, solicitationId, propertyIzeeId } = this.props

    let applicantsSelected = this.state.applicantsSelected

    this.setState({
      isLoading: true,
      message: 'Adicionando pretendentes...',
    })

    request
      .get(`${baseURL}company/${companyId}/property/${propertyIzeeId}`)
      .then((response) => {
        const property = response.data
        const propertyOwner = response.data.property_owners[0]

        this.setState({ isLoading: true, message: 'Adicionando pretendentes...' })
        applicantsSelected.map((applicant, index) => {
          integrations
            .apps({
              appName: 'fichacerta',
              companyId,
              payload: {
                action: 'APPLICANT_ADD',
                companyId,
                companyDocument,
                solicitationId: solicitationId,
                applicant: applicant.applicant,
                applicantType: applicant.applicantType,
                property,
                propertyOwner,
              },
            })
            .then(({ success, data }) => {
              if (!data.app.response.success) {
                // se deu erro ao adicionar o pretendente, retorna o erro
                this.setState({ isLoading: false })
                this.props.onSuccess(success, data, false)
                return
              }

              if (index + 1 === applicantsSelected.length) {
                // se foi o ultimo pretendente adicionado executa o laudo
                this.executeReport(
                  companyId,
                  solicitationId,
                  success,
                  data,
                  'Pretendentes adiconados',
                ) // executa o laudo
              }
            })
            .catch((error) => {
              console.log('Fichacerta create error', error)
            })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateApplicant(item) {
    const { companyId, solicitationId } = this.props
    let url = ''

    if (
      item.applicantType === 'INQUILINO' ||
      item.applicantType === 'CONJUGE_INQUILINO' ||
      (item.applicantType === 'OUTROS' && item.izeeApplicantType === 'INQUILINO-PJ')
    ) {
      url = `${baseURL}company/${companyId}/client/${item.izeeId}`
    }

    if (
      item.applicantType === 'FIADOR' ||
      item.applicantType === 'CONJUGE_FIADOR' ||
      (item.applicantType === 'OUTROS' && item.izeeApplicantType === 'FIADOR-PJ')
    ) {
      url = `${baseURL}company/${companyId}/guarantor/${item.izeeId}`
    }

    this.setState({
      isLoading: true,
      message: 'Atualizando informações do pretendente...',
    })

    request
      .get(url)
      .then((response) => {
        const applicantUpdate = response.data

        integrations
          .apps({
            appName: 'fichacerta',
            companyId,
            payload: {
              action: 'APPLICANT_UPDATE',
              companyId,
              solicitationId: solicitationId,
              applicantId: item.id,
              applicant: applicantUpdate,
              applicantType: item.applicantType,
            },
          })
          .then(({ success, data }) => {
            if (!data.app.response.success) {
              // se deu erro ao atualizar pretendente, retorna o erro
              this.setState({ isLoading: false })
              this.props.onSuccess(success, data, true)
              return
            }

            this.reprocessReport(
              companyId,
              solicitationId,
              success,
              data,
              'Análise do pretendente atualizada',
            ) // reprocessa o laudo
          })
          .catch((error) => {
            console.log('Fichacerta create error', error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  updateSolicitation() {
    const { companyId, solicitationId, propertyIzeeId } = this.props
    this.setState({
      isLoading: true,
      message: 'Atualizando informações da solicitação...',
    })
    request
      .get(`${baseURL}company/${companyId}/property/${propertyIzeeId}`)
      .then((response) => {
        const property = response.data
        const propertyOwner = response.data.property_owners[0]

        integrations
          .apps({
            appName: 'fichacerta',
            companyId,
            payload: {
              action: 'UPDATE',
              companyId,
              solicitationId: solicitationId,
              property,
              propertyOwner,
            },
          })
          .then(({ success, data }) => {
            if (!data.app.response.success) {
              // se deu erro ao atualizar a solicitação, retorna o erro
              this.setState({ isLoading: false })
              this.props.onSuccess(success, data, true)
              return
            }

            this.reprocessReport(
              companyId,
              solicitationId,
              success,
              data,
              'Análise da locação atualizada',
            ) // reprocessa o laudo
          })
          .catch((error) => {
            console.log('Fichacerta create error', error)
          })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  executeReport(companyId, solicitationId, successCreate, dataCreate, message) {
    this.setState({ message: 'Solicitando laudo dos pretendentes...' })
    integrations
      .apps({
        // executa o laudo
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_EXECUTE',
          companyId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro na criação do laudo, retorna o erro
          this.setState({ isLoading: false, message: '' })
          this.props.onSuccess(success, data, true)
          return
        }

        this.getSolicitation()
        this.setState({ isLoading: false, message: '' })
        this.props.onSuccess(successCreate, dataCreate, true, message)

        return { success, data }
      })
      .catch((error) => {
        console.log('Fichacerta create error', error)
      })
  }

  reprocessReport(companyId, solicitationId, successCreate, dataCreate, message) {
    this.setState({ message: 'Reprocessando laudo...' })
    integrations
      .apps({
        // reprocessa o laudo
        appName: 'fichacerta',
        companyId,
        payload: {
          action: 'REPORT_REPROCESS',
          companyId,
          solicitationId: solicitationId,
        },
      })
      .then(({ success, data }) => {
        if (!data.app.response.success) {
          // se deu erro ao reprocessar o laudo, retorna o erro
          this.setState({ isLoading: false })
          this.props.onSuccess(success, data, true)
          return
        }

        this.getSolicitation()
        this.setState({ isLoading: false, message: '' })
        this.props.onSuccess(successCreate, dataCreate, true, message)

        return { success, data }
      })
      .catch((error) => {
        console.log('Fichacerta create error', error)
      })
  }
}
