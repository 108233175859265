import React from 'react'
import moment from 'moment'

import Text from 'components/lib/Text'

const UpdatedAt = ({ signers, defaultSign, signMethod }) => {
  if (!signers)
    return (
      <Text>
        *Este contrato ainda não foi enviado para assinatura. Preencha os campos
        abaixo para realizar o envio.
      </Text>
    )

  const { updated_at } = signers

  if (!defaultSign)
    return (
      <Text>
        *Este contrato ainda não foi enviado para assinatura. Preencha os campos
        abaixo para realizar o envio.
      </Text>
    )

  if (signMethod === defaultSign) {
    const dateAndTime = updated_at.split(' ')
    const date = dateAndTime[0]
    const time = dateAndTime[1]
    const formattedDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    const returnDate = `${formattedDate} às ${time}`
    const method =
      signMethod === 'PRINT' ? 'contrato físico' : 'assinatura eletrônica'

    return (
      <Text>
        Último contrato solicitado por <strong>{method}</strong> em :{' '}
        <strong>{returnDate}</strong>
      </Text>
    )
  }

  if (signMethod !== defaultSign) {
    const text =
      defaultSign !== 'PRINT'
        ? 'Contrato físico ainda não solicitado'
        : 'Assinatura eletrônica ainda não solicitada'

    return <Text>{text}</Text>
  }
}

export default UpdatedAt
