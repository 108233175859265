import React from 'react'

import { ga } from '../../../../../izee-app/src/services/firebase'

import Dropdown from 'components/lib/Dropdown'
import Tooltip from 'components/lib/Tooltip'
import Button from 'components/lib/Button'
import Profile from 'components/Profile'
import Icon from 'components/lib/Icon'

import { ContainerHeaderRight, MenuRow } from './styles'

const HeadRight = (props) => {
  const {
    archiveClick,
    editClick,
    editResponsibleClick,
    startProcess,
    isEditDisabled = false,
    extraButtons,
    reactToPrint,
    sharePrClick,
    canStartProcess = true,
    isAgent = false,
    disabledIf = [6, 7],
  } = props

  let pageWidth = document.body.offsetWidth

  const ButtonWithToolTip = ({ children }) =>
    canStartProcess ? (
      children
    ) : (
      <Tooltip description='O plano de assinatura atual não permite a execução do processo.'>
        {children}
      </Tooltip>
    )

  const qtyButtons = [
    archiveClick,
    editClick,
    editResponsibleClick,
    startProcess,
    extraButtons,
    reactToPrint,
    sharePrClick,
  ].filter(Boolean).length

  const checkIfGoUsingDropdown = () => {
    if (pageWidth > 1050) return false

    if (pageWidth > 800) return qtyButtons > 4

    if (pageWidth > 600) return qtyButtons > 3

    return qtyButtons > 2
  }

  return (
    <ContainerHeaderRight className='buttons '>
      <Profile disabledIf={disabledIf}>
        {checkIfGoUsingDropdown() ? (
          <Dropdown
            isRight={pageWidth > 768}
            trigger={
              <Button hasThemeColor isFlat specSelector='proposal-options-dropdown'>
                Opções
                <Icon name='fas fa-arrow-down' />
              </Button>
            }
          >
            <>
              {startProcess && (
                <Dropdown.Item
                  onClick={() => {
                    if (canStartProcess) {
                      ga.logEvent('options_menu', { custom_value: 'start-process' })
                      startProcess()
                    }
                  }}
                >
                  <ButtonWithToolTip>
                    <Button
                      isBare
                      isFullWidth
                      isMedium
                      specSelector='proposal-start-process'
                      style={
                        !canStartProcess && {
                          color: '#999',
                          cursor: 'not-allowed',
                        }
                      }
                    >
                      <Icon name='fas fa-play' />
                      Iniciar processo
                    </Button>
                  </ButtonWithToolTip>
                </Dropdown.Item>
              )}
              {editClick && (
                <Dropdown.Item
                  onClick={() => {
                    ga.logEvent('options_menu', { custom_value: 'edit' })
                    editClick()
                  }}
                >
                  <Button
                    title={
                      isEditDisabled &&
                      'Edição desabilitada em função da análise do processo'
                    }
                    isBare
                    isFullWidth
                    isMedium
                  >
                    <Icon name='fas fa-edit' />
                    Editar
                  </Button>
                </Dropdown.Item>
              )}
              <Dropdown.Item>{extraButtons()}</Dropdown.Item>
              {editResponsibleClick && (
                <Dropdown.Item
                  onClick={() => {
                    ga.logEvent('options_menu', { custom_value: 'reassign' })
                    editResponsibleClick()
                  }}
                >
                  <Button isBare isFullWidth isMedium>
                    <Icon name='fas fa-edit' />
                    Alterar Responsável
                  </Button>
                </Dropdown.Item>
              )}
              {reactToPrint && !isAgent && (
                <Dropdown.Item
                  onClick={() => {
                    ga.logEvent('options_menu', { custom_value: 'print' })
                  }}
                >
                  {reactToPrint()}
                </Dropdown.Item>
              )}
              {sharePrClick && (
                <Dropdown.Item
                  onClick={() => {
                    ga.logEvent('options_menu', { custom_value: 'share' })
                    sharePrClick()
                  }}
                >
                  <Button isBare isFullWidth isMedium>
                    <Icon name='fas fa-share' />
                    Compartilhar
                  </Button>
                </Dropdown.Item>
              )}
              {archiveClick && !isAgent && (
                <Dropdown.Item
                  onClick={() => {
                    archiveClick()
                    ga.logEvent('options_menu', { custom_value: 'archive' })
                  }}
                >
                  <Button isBare isFullWidth isMedium>
                    <Icon name='fas fa-archive' />
                    Arquivar
                  </Button>
                </Dropdown.Item>
              )}
            </>
          </Dropdown>
        ) : (
          <MenuRow>
            {startProcess && (
              <ButtonWithToolTip>
                <Button
                  hasThemeColor
                  specSelector='proposal-start-process'
                  style={
                    canStartProcess
                      ? {
                          marginRight: '10px',
                        }
                      : {
                          marginRight: '10px',
                          color: '#333',
                          cursor: 'not-allowed',
                          background: '#ddd',
                        }
                  }
                  onClick={() => {
                    if (canStartProcess) {
                      ga.logEvent('options_menu', { custom_value: 'start-process' })
                      startProcess()
                    }
                  }}
                >
                  <Icon name='fas fa-play' />
                  Iniciar processo
                </Button>
              </ButtonWithToolTip>
            )}
            {editClick && (
              <Button
                hasThemeColor
                title={
                  isEditDisabled &&
                  'Edição desabilitada em função da análise do processo'
                }
                onClick={() => {
                  ga.logEvent('options_menu', { custom_value: 'edit' })
                  editClick()
                }}
              >
                <Icon name='fas fa-edit' />
                Editar
              </Button>
            )}
            {typeof extraButtons === 'function' ? extraButtons(true) : extraButtons}
            {editResponsibleClick && (
              <Button
                hasThemeColor
                onClick={() => {
                  ga.logEvent('options_menu', { custom_value: 'reassign' })
                  editResponsibleClick()
                }}
              >
                <Icon name='fas fa-edit' />
                Alterar Responsável
              </Button>
            )}
            {reactToPrint && !isAgent && (
              <div
                onClick={() => {
                  ga.logEvent('options_menu', { custom_value: 'print' })
                }}
                style={{ marginRight: '10px' }}
              >
                {reactToPrint(true)}
              </div>
            )}
            {sharePrClick && (
              <Button
                hasThemeColor
                onClick={() => {
                  ga.logEvent('options_menu', { custom_value: 'share' })
                  sharePrClick()
                }}
              >
                <Icon name='fas fa-share' />
                Compartilhar
              </Button>
            )}
            {archiveClick && !isAgent && (
              <Button
                hasThemeColor
                onClick={() => {
                  archiveClick()
                  ga.logEvent('options_menu', { custom_value: 'archive' })
                }}
              >
                <Icon name='fas fa-archive' />
                Arquivar
              </Button>
            )}
          </MenuRow>
        )}
      </Profile>
    </ContainerHeaderRight>
  )
}

export default HeadRight
