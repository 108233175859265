import React, { useEffect, useRef, useCallback } from 'react'
import ReactToPrint from 'react-to-print'

import withRedux from 'components/redux'
import * as actions from './actions'
import * as commons from 'utils/UtilityStore/actions'
import * as mainActions from '../../actions'

// Components
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'
import SectionWrapper from 'components/SectionWrapper'
import CreateSurveyProcess from '../../../../services/integrations/components/RedeVistorias/CreateSurveyProcess'
import ToggleRequirement from 'features/ToggleRequirement'
import HeadRight from 'features/AnalysisProposal/components/HeadRight'
import SectionGroup from 'features/AnalysisProposal/components/SectionGroup'
import ProcessTask from 'services/ProcessTask'

const Survey = ({ current, activeCompany, isAgent, notification, fetch }) => {
  const componentRef = useRef(null)

  useEffect(() => {
    const allButtons = document.querySelectorAll('#form-blocked button')

    allButtons.forEach((currentButton) => {
      const clone = currentButton.cloneNode(true)
      const parent = currentButton.parentNode

      parent.appendChild(clone)
      currentButton.remove()
    })
  }, [])

  const submitScheduleSuccess = useCallback(
    ({ isSuccess }) => {
      if (isSuccess) {
        notification({ message: 'Agendamento da vistoria concluído com sucesso' })
        fetch(current.id)
      } else {
        notification({
          message: 'Ocorreu um erro ao tentar agendar a vistoria',
          status: 'danger',
        })
      }
    },
    [current.id, fetch, notification],
  )

  const closeRequirementSuccess = useCallback(
    ({ isSuccess }) => {
      if (isSuccess) {
        notification({ message: 'Etapa alterada com sucesso' })
        fetch(current.id)
      } else {
        notification({
          message: 'Ocorreu um erro ao tentar alterar a etapa',
          status: 'danger',
        })
      }
    },
    [current.id, fetch, notification],
  )

  const reactToPrint = (active) => (
    <ReactToPrint
      trigger={() => (
        <Button
          isBare={!active}
          isFullWidth={!active}
          isMedium={!active}
          hasThemeColor={active}
        >
          <Icon name='fas fa-print' />
          Imprimir
        </Button>
      )}
      bodyClass='print-padding'
      content={() => componentRef.current}
      copyStyles={true}
    />
  )

  const items = [
    {
      contain: (
        <SectionWrapper>
          <CreateSurveyProcess
            process={current}
            company={activeCompany}
            onSuccess={submitScheduleSuccess}
          />
        </SectionWrapper>
      ),
      icon: 'fa-eye',
      open: true,
      title: 'Rede Vistorias',
      info: 'Caso já tenha o painel na RV, adicione a URL de call back fornecida pelo painel RV. Caso não, faça seu cadastro inicial e solicite uma vistoria, esse cadastro será apenas na primeira vez, depois os dados serão automaticamente preenchidos.',
    },
  ]

  const { requirements } = current
  const status = ProcessTask(requirements, 'REQUIREMENT_INSPECTION').status

  return isAgent ? (
    <form id='form-blocked'>
      <fieldset disabled>
        <div ref={componentRef}>
          <div className='print-wrap'>
            <SectionWrapper customHeader header={<p>Vistoria</p>}>
              <SectionGroup items={items} />
              {activeCompany?.id && (
                <SectionWrapper header='Gerenciar etapa'>
                  <ToggleRequirement
                    processId={current.id}
                    onSuccess={closeRequirementSuccess}
                    requirement='inspection'
                    requirementStatus={status}
                    companyId={activeCompany.id}
                  />
                </SectionWrapper>
              )}
            </SectionWrapper>
          </div>
        </div>
      </fieldset>
    </form>
  ) : (
    <div ref={componentRef}>
      <div className='print-wrap'>
        <SectionWrapper
          customHeader
          header={<p>Vistoria</p>}
          headerRight={HeadRight({ reactToPrint })}
        >
          <SectionGroup items={items} />
          {activeCompany?.id && (
            <SectionWrapper header='Gerenciar etapa'>
              <ToggleRequirement
                processId={current.id}
                onSuccess={closeRequirementSuccess}
                requirement='inspection'
                requirementStatus={status}
                companyId={activeCompany.id}
              />
            </SectionWrapper>
          )}
        </SectionWrapper>
      </div>
    </div>
  )
}

export default withRedux(({ processDetails: { current } }) => ({ current }), {
  ...actions,
  ...mainActions,
  ...commons,
})(Survey)
