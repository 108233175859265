import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/lib/Modal'
import Grid from 'components/lib/Grid'
import Button from 'components/lib/Button'
import Field from 'components/lib/FormFields/components/Field'
import { FormFieldText } from 'components/lib/FormFields'
import { request } from 'core/constants'
import withRedux from 'components/redux'
import * as common from 'utils/UtilityStore/actions'

const Subtitle = styled.p`
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  color: #666;
  margin-bottom: 0.5rem;
  & > span {
    color: #ef4444;
  }
`

const FileInput = styled.input`
  display: none;
`

const UploadButton = styled.label`
  background: transparent;
  padding: 8px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
  .fa-upload {
    margin-right: 0.5rem;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
`

const MAX_FILE_SIZE_MB = 30

const UploadPaymentReceiptModal = ({ isOpen, onClose, company, notification }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [orderNumber, setOrderNumber] = useState('')
  const [file, setFile] = useState(null)

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0]
      const fileSizeMB = selectedFile.size / (1024 * 1024)

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        notification({
          message: 'O arquivo selecionado é muito grande. O tamanho máximo permitido é 30 MB.',
          status: 'warning',
        })
        setFile(null)
      } else {
        setFile(selectedFile)
      }
    }
  }

  const handleSubmit = async () => {
    if (!file) {
      notification({
        message: 'Por favor, selecione um arquivo para enviar.',
        status: 'warning',
      })

      return
    }

    try {
      setIsLoading(true)

      const requestData = new FormData()

      requestData.append('file', file)
      requestData.append('pdv_sale_id', orderNumber)

      await request.post(
        `/company/${company.id}/ficha-certa-credits-purchases/payment-receipt`,
        requestData,
      )

      notification({
        message: 'Comprovante de pagamento enviado com sucesso!',
      })

      setIsLoading(false)
      onClose()
    } catch (err) {
      setIsLoading(false)

      notification({
        message: err?.message
          ? err.message
          : 'Erro ao salvar o comprovante de pagamento',
        status: err ? 'warning' : 'danger',
      })
    }
  }

  const LoadingContainer = styled.div`
    height: 250px;
    margin-top: 100px;
  `

  const Spinner = styled.div`
    margin: 0 auto;
    padding: 45px 60px;
  `

  if (isLoading) {
    return (
      <Modal title='Enviar comprovante' isOpen={isOpen} onClose={onClose}>
        <div>
          <LoadingContainer>
            <Spinner className='empty-state-spinner' />
          </LoadingContainer>
        </div>
      </Modal>
    )
  }

  return (
    <Modal title='Enviar comprovante' isOpen={isOpen} onClose={onClose}>
      <Subtitle>
        Agilize a liberação de créditos comprados via boleto. Envie aqui o
        comprovante de pagamento informando o número do pedido para realizar análises
        o quanto antes.
      </Subtitle>

      <Grid>
        <Grid.Col>
          <Label>
            Número do pedido <span>*</span>
          </Label>
          <Field>
            <FormFieldText
              value={orderNumber}
              onChange={(v) => setOrderNumber(v)}
              placeholder='Digite o número do pedido'
              valueDefault={''}
            />
          </Field>
        </Grid.Col>
        <Grid.Col>
          <Label>
            Anexar comprovante de pagamento <span>*</span>
          </Label>
          <UploadButton
            htmlFor='file-upload'
            className='has-theme-border-color has-content-theme-color'
          >
            <i className='fas fa-upload'></i>
            Upload de arquivo
          </UploadButton>
          <FileInput
            id='file-upload'
            type='file'
            accept='.pdf,image/*'
            onChange={handleFileChange}
          />
          {file && <p>{file.name}</p>}
        </Grid.Col>
      </Grid>
      <ButtonGroup>
        <Button isBare onClick={onClose}>
          Cancelar
        </Button>
        <Button
          hasThemeColor
          onClick={handleSubmit}
          disabled={!orderNumber || !file}
        >
          Enviar
        </Button>
      </ButtonGroup>
    </Modal>
  )
}

export default withRedux(
  (store) => ({
    company: store.app.activeCompany,
    user: store.app.activeUser,
  }),
  { ...common },
)(UploadPaymentReceiptModal)
