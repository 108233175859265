import React, { useState, useEffect } from 'react'
import { Callout } from '@blueprintjs/core'

import { request } from 'core/constants'

// Components
import RadioGroup from 'components/lib/RadioGroup'
import Grid from 'components/lib/Grid'
import Space from 'components/lib/Space'
import Button from 'components/lib/Button'
import Header from 'components/lib/Header'

import formCreator from 'features/components/formCreator'

import { getFileSize } from 'utils/utils'
import uploadFile from '../../../../../uploadFile'

import AttachmentsList from './AttachmentsList'
import SignersList from './SignersList'
import UpdatedAt from './UpdatedAt'

import { deepKey, copyToClipboard } from 'utils/utils'

export const Settings = (props) => {
  const { proposal, hasFinishedInspection } = props

  const [signMethod, setSignMethod] = useState()
  const [attachInspectionReport, setAttachInspectionReport] = useState(false)
  const [defaultSign, setDefaultSign] = useState()
  const [signers, setSigners] = useState([])

  const [requesting, setRequesting] = useState(false)
  const [error, setError] = useState()

  const [uploadedFiles, setUploadedFiles] = useState([])

  const handleRemoveFile = (id) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id))
  }

  const handleFileUpload = async (e) => {
    const file = e.target.files[0]

    const { companyId } = props

    const fileId = await uploadFile(e.target.files[0], null, companyId)

    if (file) {
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        {
          id: fileId,
          name: file.name,
          size: `${(file.size / 1024 / 1024).toFixed(2)}mb`,
        },
      ])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const signMethod = proposal.contract.sign_method

      setDefaultSign(signMethod)
      setSignMethod(signMethod)
      setAttachInspectionReport(proposal.contract.attach_inspection_report)

      const uploadedFiles = await Promise.all(
        proposal.contract.attachments.map(async (a) => {
          const size = await getFileSize(a.file.source)

          return {
            id: a.file_id,
            name: a.file.name,
            size: `${(size / 1024 / 1024).toFixed(2)}mb`,
            source: a.file.source,
          }
        }),
      )

      setUploadedFiles(uploadedFiles)

      const {
        contract: { signers },
      } = proposal

      setSigners(signers)
    }

    fetchData()
  }, [proposal])

  const updateSigners = (field, value, index) => {
    const updatedSigners = [...signers]

    updatedSigners[index][field] = value
    setSigners(updatedSigners)
  }

  const addNewSigner = () => {
    setSigners((oldSigners) => [
      ...oldSigners,
      { name: '', email: '', is_witness: '', signs_for_owner: false, cpf: '' },
    ])
  }

  const copyLink = (item) => {
    const href = deepKey(item, 'signature_link')

    if (href) {
      copyToClipboard(href)

      return props.notification({
        message: 'Link de assinatura copiado para a área de transferência',
        status: 'success',
      })
    } else {
      return props.notification({
        message: 'Link de assinatura indisponível',
        status: 'danger',
      })
    }
  }

  const removeSigner = (index) => {
    const updatedSigners = [...signers]

    updatedSigners.splice(index, 1)
    setSigners(updatedSigners)
    setError()
  }

  const createRequest = async () => {
    try {
      const { processId, onSuccess } = props

      const data = {
        owners_sign: false,
        sign_method: signMethod,
        signers,
        attach_inspection_report: attachInspectionReport,
        attachment_files: uploadedFiles.map((f) => {
          return { id: f.id }
        }),
      }

      const endpoint = `{company}/process/${processId}/contract/define-sign-method`

      setError()
      setRequesting(true)
      await request.post(endpoint, data)
      onSuccess({ isSuccess: true })
    } catch (error) {
      setError(error)
    } finally {
      setRequesting(false)
    }
  }

  const { formIsWorking, formIsReady, isClosed } = props
  const submitIsDisabled =
    (!formIsReady || formIsWorking || !signMethod) && signMethod !== 'PRINT'

  return (
    <>
      <UpdatedAt
        signers={proposal.contract.signers ? proposal.contract.signers[0] : null}
        defaultSign={defaultSign}
        signMethod={signMethod}
      />

      <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
        INFORMAÇÕES DO CONTRATO
      </Header>

      <RadioGroup
        name='sign_method'
        title='Tipo de contrato a ser enviado'
        options={[
          { value: 'AUTENTIQUE', label: 'Assinatura Assinatura eletrônica' },
          { value: 'PRINT', label: 'Contrato físico' },
        ]}
        value={signMethod}
        isDisabled={isClosed}
        onChange={setSignMethod}
      />

      <SignersList
        disabled={submitIsDisabled}
        signers={signers}
        updateSigners={updateSigners}
        removeSigner={removeSigner}
        addNewSigner={addNewSigner}
        errors={error?.errors || []}
        copyLink={copyLink}
      />

      <Space />

      <AttachmentsList
        attachInspectionReport={attachInspectionReport}
        setAttachInspectionReport={setAttachInspectionReport}
        uploadedFiles={uploadedFiles}
        handleRemoveFile={handleRemoveFile}
        handleFileUpload={handleFileUpload}
        isClosed={isClosed}
        hasFinishedInspection={hasFinishedInspection}
      />

      <Grid>
        {!isClosed && (
          <Grid.Col isRight>
            <Grid.Col>
              <Button
                hasThemeColor
                isLoading={formIsWorking || requesting}
                isDisabled={submitIsDisabled}
                onClick={createRequest}
              >
                Enviar Contrato
              </Button>
            </Grid.Col>
          </Grid.Col>
        )}
      </Grid>

      {error && <Callout intent='danger'>{error.message}</Callout>}
    </>
  )
}

export default formCreator(Settings)
