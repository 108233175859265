import styled from 'styled-components'

export const Container = styled.div``

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  z-index: 0;
`

export const SectionStatus = styled.p`
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  padding: 0 20px 0 10px;
  color: rgba(0, 0, 0, 0.3);
`

export const Pretendente = styled.div`
  padding: 8px;
  border: 2px solid ${(props) => props.color || '#F5F5F5'};
  background-color: #fbfbfb;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 5px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const ContainerHeaderRight = styled.div`
  position: relative;
`

export const MenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  button {
    font-size: 13px;
    padding: 2px 12px;
    background-color: #00a2dd;
  }
`
