import React from 'react'
import PropTypes from 'prop-types'

const RadioGroup = ({ options, name, value, onChange, title, isDisabled }) => {
  return (
    <div className='radio-group'>
      {title && (
        <label className='radio-group-title'>
          {title} <span className='required'>*</span>
        </label>
      )}
      <div className='radio-options'>
        {options.map((option) => (
          <label key={option.value} className='radio-label'>
            <input
              type='radio'
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={(e) => onChange(e.target.value)}
              disabled={isDisabled}
              className='radio-input'
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default RadioGroup
