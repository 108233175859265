export default function mtel(v) {
  if (!v) return ''
  const hasPlus = v.startsWith('+')

  let digits = v.replace(/[^\d]/g, '')

  if (hasPlus) {
    if (!digits) return '+'
    if (digits.length > 15) {
      digits = digits.slice(0, 15)
    }
    
    return '+' + digits
  }

  // limit for BR phone
  if (digits.length > 11) {
    digits = digits.slice(0, 11)
  }

  // partial BR format
  const p1 = digits.slice(0, 2)
  const rest = digits.slice(2)

  let formatted = p1 ? `(${p1}` : ''

  if (rest.length > 0) formatted += ') '
  if (rest.length <= 8) {
    // up to (99) 9999-9999
    const a = rest.slice(0, 4)
    const b = rest.slice(4)

    formatted += b.length > 0 ? `${a}-${b}` : a
  } else {
    // handle 11 digits => (99) 99999-9999
    const a = rest.slice(0, 5)
    const b = rest.slice(5)

    formatted += b.length > 0 ? `${a}-${b}` : a
  }

  return formatted
}
