import React from 'react'
import ReactToPrint from 'react-to-print'
import { request } from 'core/constants'
import { connect } from 'react-redux'
import { isAfter, parseISO } from 'date-fns'

import * as actions from './actions'
import * as commons from 'utils/UtilityStore/actions'
import Icon from 'components/lib/Icon'

// Components
import BodyContainer from 'components/lib/BodyContainer'
import ScreenHeader from 'components/ScreenHeader'
import SectionWrapper from 'components/SectionWrapper'
import SectionGroup from 'features/AnalysisProposal/components/SectionGroup'
import HeadRight from 'features/AnalysisProposal/components/HeadRight'
import Modal from 'components/lib/Modal'
import Button from 'components/lib/Button'
import Address from 'components/Proposal/components/Address'
import Person from '../ProcessDetails/containers/Overview/components/Person'
import Documents from 'components/Documents'
import PropertyInfo from 'components/Proposal/components/PropertyInfo'
import Negotiation from 'components/Proposal/components/Negotiation'
import Options from './components/Options'

import Evaluation from 'components/Proposal/components/Evaluation'
import EditResponsible from 'containers/Proposal/components/EditResponsible'

import ArchiveProposal from 'features/ArchiveProposal'
import StartProcessConfirmation from 'features/StartProcessConfirmation'
import EditProposal from 'containers/Proposal/components/New'
import withModal from 'components/HOCs/withModal'
import withFilePreview from 'components/HOCs/withFilePreview'

import Logs from 'components/Logs'
import ProposalPrint from 'components/Proposal'

// Utils
import { timeDelay, copyToClipboard } from 'utils/utils'
import { currency } from 'components/Proposal/components/utils'
import formatAddress from '../ProcessDetails/containers/Overview/components/formatAddress'
import { fb, ga } from '../../services/firebase'
import Fichacerta from '../../services/integrations/components/Fichacerta'

class ProposalDetails extends React.Component {
  proposalId = undefined

  constructor(props) {
    super(props)

    this.proposalId = props.match.params.proposalId

    this.state = {
      isResending: false,
      isAgent: false,
      refusedFiles: {},
    }
  }

  componentDidMount() {
    this.props.fetch(this.props.match.params.proposalId)

    const profiles = this.props.company.profiles

    profiles.map((profile) => {
      if (profile.code === 'PROFILE_AGENT') {
        this.setState({ isAgent: true })
      }
    })
  }

  componentWillUnmount() {
    this.props.clear()
  }

  render() {
    let current = this.props.current

    let company = this.props.company

    //Geral use consts
    const id = current.id || ''
    const property = current?.properties
      ? current?.properties[0]
      : current?.temporary_property
      ? current?.temporary_property
      : {}

    //Property
    const renderPropertyTitle = `Imóvel ${
      property?.type ? `(${property?.type?.name})` : ''
    }
      ${property?.name ? ` // Apelido: ${property?.name}` : ''}`

    const renderPropertyContain = ({ address }) => (
      <React.Fragment>
        <strong>
          <span style={{ fontSize: 14 }}>{property.name}</span>
        </strong>
        &nbsp;&nbsp;
        <Button
          isBare
          alt='Editar o cadastro'
          onClick={this.editProperty.bind(this, property)}
        >
          <Icon name='fas fa-pen-square' />
        </Button>
        <p>
          <strong>
            <span style={{ fontSize: 14 }}>Matrícula</span>
          </strong>
          : {property.registration}
        </p>
        <p>
          <strong>
            <span style={{ fontSize: 14 }}>Inscrição Imobiliária</span>
          </strong>
          : {property.real_state_enrollment}
        </p>
        <Address address={address} />
      </React.Fragment>
    )

    const propertyOwners = current.property_owners || []

    let isPropValid = null
    const ownerList = propertyOwners.map((item) => {
      isPropValid =
        isPropValid !== 2 && isPropValid !== 0 ? item.evaluation : isPropValid
      item.type = 'propertyOwners'
      const tokens = item.operation_tokens

      const other = {
        ...item,
        sectionIconName: 'user',
        sectionTitle: 'Proprietário',
      }

      return (
        <SectionWrapper className='no-py' key={item.id}>
          <Person
            {...item}
            onClickResend={() =>
              this.resendProposal({
                proposalId: id,
                email: item.email,
                type: 'property_owner_ids',
                id: [item.id],
              })
            }
            isValidLink={!!this.getValidToken(tokens)}
            onClickCopyI={() => this.copyProposalLink(tokens)}
            onClickWhatsapp={() => this.sendLinkWhatsapp(tokens, item, 'owner')}
            onClick={this.editOwner.bind(this)}
            key={item.id}
          />
          <SectionWrapper className='group-white' header='Documentos'>
            <Documents entityId={item.id} entityName='propertyOwner' other={other} />
          </SectionWrapper>
        </SectionWrapper>
      )
    })

    //Clients
    const clients = current.clients || []

    let isClientValid = null
    const clientList = clients.map((item) => {
      const refusedFiles = this.state.refusedFiles[item.email] || []
      const other = {
        ...item,
        sectionIconName: 'user',
        sectionTitle: 'Cliente',
      }

      isClientValid =
        isClientValid !== 2 && isClientValid !== 0 ? item.evaluation : isClientValid

      const tokens = item.operation_tokens

      return (
        <SectionWrapper className='no-py' key={item.id}>
          <Person
            {...item}
            onClickResend={() =>
              this.resendProposal({
                proposalId: id,
                email: item.email,
                type: 'client_ids',
                id: [item.id],
              })
            }
            isValidLink={!!this.getValidToken(tokens)}
            onClickCopyI={() => this.copyProposalLink(tokens)}
            onClickWhatsapp={() => this.sendLinkWhatsapp(tokens, item, 'client')}
            onClick={this.editClient.bind(this)}
            refusedFiles={refusedFiles}
            fetchFile={this.props.openFile}
            removeFileFromRejection={this.removeFileFromRejection.bind(this)}
            rejectFile={!status && this.rejectFile.bind(this)}
            requestNewDocuments={this.requestNewDocuments.bind(this, 'client')}
            key={item.id}
          />
          <SectionWrapper className='group-white' header='Documentos'>
            <Documents entityId={item.id} entityName='client' other={other} />
          </SectionWrapper>
        </SectionWrapper>
      )
    })

    //Guarantee && Guarantors
    const guarantee = current.guarantee || {}
    const guarantors = current.guarantors || []

    const guarantorTitle =
      guarantee && guarantee.name ? `Garantia (${guarantee.name})` : 'Garantias'

    let isClientGuarantee = guarantee?.code === 'GUARANTEE_GUARANTOR'

    let isGuarantorValid = null
    const guarantorList = guarantors.map((item) => {
      const refusedFiles = this.state.refusedFiles[item.email] || []
      const other = {
        ...item,
        sectionIconName: 'user',
        sectionTitle: 'Fiador',
      }

      isGuarantorValid =
        isGuarantorValid !== 2 && isGuarantorValid !== 0
          ? item.evaluation
          : isGuarantorValid

      const tokens = item.operation_tokens

      return (
        <SectionWrapper className='no-py' key={item.id}>
          <Person
            {...item}
            onClickResend={() =>
              this.resendProposal({
                proposalId: id,
                email: item.email,
                type: 'guarantor_ids',
                id: [item.id],
              })
            }
            isValidLink={!!this.getValidToken(tokens)}
            onClickCopyI={() => this.copyProposalLink(tokens)}
            onClickWhatsapp={() => this.sendLinkWhatsapp(tokens, item, 'guarantor')}
            onClick={this.editGuarantor.bind(this)}
            refusedFiles={refusedFiles}
            fetchFile={this.props.openFile}
            removeFileFromRejection={this.removeFileFromRejection.bind(this)}
            rejectFile={!status && this.rejectFile.bind(this)}
            requestNewDocuments={this.requestNewDocuments.bind(this, 'guarantor')}
            key={item.id}
          />
          <SectionWrapper className='group-white' header='Documentos'>
            <Documents entityId={item.id} entityName='guarantor' other={other} />
          </SectionWrapper>
        </SectionWrapper>
      )
    })

    //Value
    const isRental = current.type === 'TYPE_RENTAL'
    const proposalValue = currency(
      isRental ? current.rent_value : current.sale_value,
    )
    const titleProposalValue = `Valor ${
      isRental ? 'do aluguel' : 'da venda'
    }: R$ ${proposalValue}`

    const propertyInfo = (
      <PropertyInfo property={property} goal={this.props.current.goal} isProposal />
    )

    //Negotiation
    const negotiation = current.negotiation ? current.negotiation : ''
    const negotiationInfo = <Negotiation>{negotiation}</Negotiation>

    //Estimate Date and key Location
    const optionsInfo = (
      <Options
        companyId={this.props.parentProps.activeCompany.id}
        proposalId={this.proposalId}
        isRental={isRental}
        defaultValue={{
          ownershipDate: current.ownership_date || '',
          keysLocation: current.keys_location || '',
          payday: current.payday || '',
          validityMonths: current.validity_months || '',
        }}
        onSuccess={this.changeOptionSuccess.bind(this)}
      />
    )

    const fichacerta = () => {
      if (current.id) {
        return (
          <Fichacerta
            props={this.props}
            companyId={this.props.activeCompany.id}
            payload={current}
          />
        )
      }
    }

    const items = [
      ...(property
        ? [
            {
              contain: renderPropertyContain(property),
              icon: 'fa-home',
              open: false,
              title: renderPropertyTitle,
              type: 'imovel',
            },
          ]
        : []),

      {
        contain: ownerList,
        evaluation: <Evaluation status={isPropValid} />,
        icon: 'fa-user',
        open: false,
        title: 'Proprietários',
        type: 'props',
      },
      {
        contain: clientList,
        evaluation: <Evaluation status={isClientValid} />,
        icon: 'fa-user',
        open: false,
        title: 'Clientes',
        type: 'clients',
      },
      ...(isRental
        ? [
            {
              contain: guarantorList.length ? guarantorList : [],
              evaluation: isClientGuarantee && (
                <Evaluation status={isGuarantorValid} />
              ),
              icon: 'fa-shield-check',
              open: false,
              title: guarantorTitle,
              type: 'guarantor',
            },
          ]
        : []),
      {
        contain: fichacerta(),
        icon: 'fa-diagnoses',
        open: false,
        title: 'Análise da Proposta',
        type: 'analysis',
      },
      {
        contain: propertyInfo,
        icon: 'fa-hand-holding-usd',
        open: false,
        title: titleProposalValue,
        type: 'rentValue',
      },
      {
        contain: negotiationInfo,
        icon: 'fa-handshake',
        open: false,
        title: 'Negociação',
        type: 'negotiation',
      },
      {
        contain: optionsInfo,
        icon: 'fa-calendar-check',
        open: true,
        title: 'Datas e Prazos',
        type: 'keys_location',
      },
      {
        contain: <Logs proposalId={this.proposalId} />,
        icon: 'fa-calendar-check',
        open: false,
        title: 'Logs',
        type: 'logs',
      },
    ]

    const { process_status_url } = current

    const copyToClipboardClick = () => {
      const path = `${process_status_url}`

      copyToClipboard(path)
      this.props.notification({
        message: 'Link copiado com sucesso',
        status: 'success',
      })
    }

    const extraButtons = (active) => (
      <Button
        isBare={!active}
        isFullWidth={!active}
        isMedium={!active}
        hasThemeColor={active}
        onClick={() => {
          ga.logEvent('options_menu', { custom_value: 'public-link' })
          copyToClipboardClick()
        }}
      >
        <Icon name='fas fa-link' />
        Link de Acompanhamento
      </Button>
    )

    const reactToPrint = (active = false) => (
      <ReactToPrint
        trigger={() => (
          <Button
            isBare={!active}
            isFullWidth={!active}
            isMedium={!active}
            hasThemeColor={active}
          >
            <Icon name='fas fa-print' />
            Imprimir
          </Button>
        )}
        content={() => this.componentRef}
        copyStyles={true}
        bodyClass='print-padding'
      />
    )

    const editResponsibleClick = () => {
      this.props.openModal(
        'Alterar Responsável',
        <EditResponsible
          proposal={current}
          activeCompanyId={company.id}
          onSuccess={this.onEditResponsibleClick.bind(this)}
        />,
      )
    }

    const editClick = this.editProposal.bind(this, current, company)
    const startProcess = this.onStartProcess.bind(this, current.id)
    const archiveClick = this.archiveProposal.bind(this, current.id, company)

    const canStartProcess =
      this.props.activeCompany.subscription_plan?.maximum_parallel_processes !== 0

    return (
      <div>
        <div className='visibleToPrint'>
          <ProposalPrint
            data={current}
            company={company}
            ref={(element) => (this.componentRef = element)}
          />
        </div>
        <ScreenHeader
          header={`Nova proposta de ${isRental ? 'locação' : 'venda'}`}
          onClick={this.props.historyPush.bind(this)}
        />

        <BodyContainer isLoading={this.props.isWorking['proposal']}>
          <div className='print-wrap'>
            <SectionWrapper
              style={{ marginBottom: '3rem' }}
              customHeader={true}
              header={'Dados da proposta ' + current.code}
              headerRight={
                !current.process
                  ? HeadRight({
                      reactToPrint,
                      editClick,
                      editResponsibleClick,
                      startProcess,
                      canStartProcess,
                      archiveClick,
                      extraButtons,
                      isAgent: this.state.isAgent,
                    })
                  : HeadRight({
                      reactToPrint,
                      editClick,
                      editResponsibleClick,
                      archiveClick,
                      extraButtons,
                      isAgent: this.state.isAgent,
                    })
              }
            >
              <SectionGroup items={items} />
            </SectionWrapper>
            <Modal
              title={this.state.modalTitle}
              isOpen={this.state.modalIsOpen}
              onClose={this.handleModalClose.bind(this)}
            >
              {this.state.feature}
            </Modal>
          </div>
        </BodyContainer>
      </div>
    )
  }

  handleModalClose() {
    this.setState({
      modalTitle: '',
      modalIsOpen: false,
      feature: null,
    })
  }

  closeModal() {
    this.props.closeModal()
  }

  editProposal(proposal, companyId) {
    const { activeCompany } = this.props

    this.props.openModal(
      'Editar proposta',
      <EditProposal
        {...this.props}
        proposalId={proposal.id}
        activeCompanyModel={activeCompany}
        activeCompany={companyId}
        defaultValue={proposal}
        onSuccess={this.onEditProposalSuccess.bind(this)}
      />,
    )
  }

  onEditResponsibleClick(response) {
    if (response.isSuccess) {
      this.props.notification({ message: 'Responsável alterado com sucesso' })
      this.props.fetch(this.props.current.id)
      this.props.closeModal()
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar alterar o responsável',
        status: 'danger',
      })
    }
  }

  archiveProposal(proposalId, companyId) {
    this.props.openModal(
      'Arquivar proposta',
      <ArchiveProposal
        proposalId={proposalId}
        activeCompany={companyId}
        onSuccess={this.onArchiveProposalSuccess.bind(this)}
      />,
    )
  }

  changeOptionSuccess({ isSuccess }) {
    if (isSuccess) {
      this.props.notification({
        message: 'Alterações atualizadas com sucesso',
      })
    } else {
      this.props.notification({
        message: 'Ocorreu um erro ao tentar atualizar a proposta',
        status: 'danger',
      })
    }
  }

  onStartProcess(proposalId) {
    this.props.openModal(
      'Dar inicio a este processo?',
      <StartProcessConfirmation
        {...this.props}
        proposalId={proposalId}
        onSuccess={this.startProcess.bind(this)}
      />,
    )
  }

  startProcess(response) {
    if (response.isSuccess) {
      ga.logEvent('process_started')
      fb.logEvent('process_started', {
        processId: response.data.proposal.process_id,
        companyId: this.props.activeCompany.id,
        user: {
          email: this.props.activeUser.email,
          id: this.props.activeUser.id,
        },
      })
      this.props.notification({
        message: response.message,
      })
      const url_path = `/app/${this.props.activeUser.id}/${this.props.activeCompany.id}/process/${response.data.proposal.process_id}/overview`

      this.props.history.push(url_path)
    } else {
      this.props.notification({
        message: response.message,
        status: 'danger',
      })
    }
  }

  onEditProposalSuccess(response) {
    this.props.closeModal()
    this.props.fetch(this.props.match.params.proposalId)
  }

  onArchiveProposalSuccess(response) {
    if (response.isSuccess) {
      this.setState({
        modalTitle: '',
        modalIsOpen: false,
        feature: null,
      })
      let delayRedirect = timeDelay(400)

      delayRedirect(() => {
        this.props.history.push('/')
      })
    } else {
      this.props.notification({
        message: response.actionMessage,
        status: 'danger',
      })
    }
  }

  resendProposal(data) {
    const { proposalId, type, email, id } = data
    const body = { [type]: id }

    request
      .post(`{company}/proposal/${proposalId}/send-to-parties`, body)
      .then((response) => {
        this.props.fetch(this.props.match.params.proposalId)
        this.props.notification({
          message: 'Proposta reenviada com sucesso para ' + email,
        })
      })
      .catch((error) => {
        this.props.notification({
          message: 'Ocorreu um erro ao tentar reenviar a proposta',
          status: 'danger',
        })
      })
  }

  getValidToken = (tokens) =>
    (tokens || []).find(
      (tk) =>
        !!tk.expiration_date && isAfter(parseISO(tk.expiration_date), new Date()),
    )

  copyProposalLink(tokens) {
    const token = this.getValidToken(tokens)

    if (!token) {
      return
    }

    const path = `${IZEE_PROCESS_URL}${token.token}`

    copyToClipboard(path)

    this.props.notification({
      message: 'Link copiado com sucesso',
      status: 'success',
    })

    return true
  }

  sendLinkWhatsapp(tokens, item, type) {
    const token = this.getValidToken(tokens)

    if (!token) {
      return
    }
    let company = this.props.activeCompany

    let id = this.props.current?.id

    let body = ''

    if (type == 'owner') {
      body = `Olá ${item?.name}, você tem uma proposta (código: ${id}) para o seu imóvel aqui na ${company?.name}. Clique no link abaixo para continuar.`
    }
    if (type == 'client') {
      body = `Olá ${item?.name}, você tem uma proposta (código: ${id}) em seu nome aqui na ${company?.name}. Clique no link abaixo para confirmar a proposta e enviar seus dados, bem simples e rápido.`
    }
    if (type == 'guarantor') {
      const client = this.props.current?.clients[0]

      body = `Olá ${item?.name}, sou da ${company?.name}, você foi escolhido por ${client?.name} como um fiador na proposta (código: ${id}). Clique no link abaixo para continuar.`
    }

    let url = `${IZEE_PROCESS_URL}${token.token}`

    let link = `https://api.whatsapp.com/send?text=${body}%0a${url}`

    let left =
      window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - 520 / 2

    let top =
      window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - 570 / 2

    window.open(
      link,
      '',
      `height=570,width=520,left=${left},top=${top}location=no,scrollbars=yes,status=no,toolbar=no,directories=no,menubar=no,resizable=no,centerscreen=yes,chrome=yes`,
    )

    return true
  }

  removeFileFromRejection(email, file) {
    let refusedFiles = this.state.refusedFiles

    refusedFiles[email] = refusedFiles[email].filter((item) => file.id !== item.id)
    this.setState({ refusedFiles: refusedFiles })
  }

  rejectFile(email, doc) {
    doc.email = email
    this.props.openModal(
      'Rejeitar documento',
      <RejectDocument
        defaultValue={doc}
        onSuccess={this.onRejectionSuccess.bind(this)}
      />,
    )
  }

  onRejectionSuccess({ data }) {
    const payload = data.payload.raw

    let refusedFiles = this.state.refusedFiles

    if (!refusedFiles[payload.email]) refusedFiles[payload.email] = []
    refusedFiles[payload.email].push({
      id: payload.id,
      reject_comment: data.payload.justification,
      is_rejected: true,
    })
    this.setState({ refusedFiles: refusedFiles })
    this.props.closeModal()
  }

  requestNewDocuments(type, object) {
    const body = { documents: this.state.refusedFiles[object.email] }

    request
      .post(
        `{company}/process/${this.props.match.params.processId}/overview/validate-${type}/${object.id}`,
        body,
      )
      .then((response) => {
        this.props.notification({
          message: 'Novos documentos solicitados com sucesso',
        })
      })
      .catch((error) => {
        this.props.notification({
          message: 'Ocorreu um erro ao tentar solicitar novos documentos',
          status: 'danger',
        })
      })
  }

  editProperty(property) {
    let current = property
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/properties/${current.id}`,
      { from: 'proposal' },
    )
  }

  editClient(client) {
    let current = client

    current = formatAddress(current)
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/guests/${current.id}`,
      { from: 'proposal' },
    )
  }

  editGuarantor(guarantor) {
    let current = guarantor

    current = formatAddress(current)
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/guarantors/${current.id}`,
      { from: 'proposal' },
    )
  }

  editOwner(owner) {
    let current = owner

    current = formatAddress(current)
    const userId = this.props.parentProps.activeUser.id
    const companyId = this.props.parentProps.activeCompany.id

    this.props.history.push(
      `/app/${userId}/${companyId}/records/property-owners/${current.id}`,
      { from: 'proposal' },
    )
  }
}

ProposalDetails.defaultProps = {
  current: {
    properties: [{}],
  },
}

export default connect(
  (store) => ({
    company: store.app.activeCompany,
    current: store.proposal.current,
    isWorking: store.commons.isWorking,
    historyPush: store.app.helpers.historyPush,
  }),
  { ...actions, ...commons },
)(withFilePreview(withModal(ProposalDetails)))
