import React from 'react'
import styled from 'styled-components'
import Button from 'components/lib/Button'
import withRedux from 'components/redux'
import * as common from 'utils/UtilityStore/actions'
import HintImageSource from 'static/media/fc-billing-receipt-hint.png'

const Container = styled.div`
  text-align: center;
`

const CheckIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #25dc27;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: white;
  font-size: 24px;
  font-weight: 900;
`

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  font-size: 0.9rem;
  font-weight: 700;
`

const Message = styled.p`
  margin-bottom: 20px;
  font-size: 0.9em;
  color: #333;
  text-align: center;
`

const Details = styled.div`
  text-align: left;
  padding: 0 12px;
`

const DetailItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  gap: 5px;
`

const Label = styled.span`
  font-weight: bold;
`

const PixCode = styled.div`
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  font-family: monospace;
  word-break: break-all;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
`

const HintImg = styled.img`
  max-width: 80%;
  height: auto;
`

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const QRCodeImage = styled.img`
  max-width: 200px;
`

const Value = styled.span`
  font-weight: normal;
`

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const BillingInfoContent = ({ billingInfo, onClose, notification }) => {
  const isPix = billingInfo?.payment_form === 'PIX'

  return (
    <Container>
      <CheckIcon>✓</CheckIcon>
      <Title>Solicitação concluída!</Title>

      {isPix ? (
        <>
          <Message>
            Você receberá uma mensagem no email cadastrado com as informações do
            pedido. Para fazer o pagamento do pedido, escaneie o QRcode abaixo, ou
            copie e cole o código Pix em seu aplicativo de pagamento.
          </Message>

          <QRCodeContainer>
            <QRCodeImage
              src={billingInfo?.sale_data?.pix?.qrcode_image}
              alt='QR Code'
            />
            <Details>
              <DetailItem>
                <Label>Número do pedido:</Label>{' '}
                <Value>{billingInfo?.sale_data?.sale?.id}</Value>
              </DetailItem>
              <DetailItem>
                <Label>Valor:</Label>
                <Value>
                  {billingInfo?.price?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Value>
              </DetailItem>
              <DetailItem>
                <Label>Quantidade:</Label>{' '}
                <Value>{billingInfo?.credits} créditos</Value>
              </DetailItem>
              <DetailItem column>
                <Label>Código pix</Label>
                <PixCode>{billingInfo?.sale_data?.pix?.qrcode}</PixCode>
              </DetailItem>

              <ButtonContainer>
                <Button
                  isFlat
                  hasThemeColor
                  onClick={() => {
                    navigator.clipboard.writeText(
                      billingInfo?.sale_data?.pix?.qrcode,
                    )
                    notification({
                      message:
                        'Código copiado com sucesso para a área de transferência',
                    })
                  }}
                >
                  Copiar código
                </Button>

                <Button
                  isFlat
                  hasThemeColor
                  onClick={() => window.open(billingInfo?.sale_data?.link, '_blank')}
                >
                  Acessar Fatura
                </Button>
              </ButtonContainer>
            </Details>
          </QRCodeContainer>
        </>
      ) : (
        <>
          <Message>
            Em breve você receberá um email com as informações do pedido e o boleto
            para pagamento. Os créditos serão incluídos em sua conta assim que o
            pagamento for reconhecido.
          </Message>

          <Details>
            <DetailItem>
              <Label>Número do pedido:</Label>{' '}
              <Value>{billingInfo?.sale_data?.sale?.id}</Value>
            </DetailItem>
            <DetailItem>
              <Label>Valor:</Label>
              <Value>
                {billingInfo?.price?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Value>
            </DetailItem>
            <DetailItem>
              <Label>Quantidade:</Label>{' '}
              <Value>{billingInfo?.credits} créditos</Value>
            </DetailItem>
          </Details>

          <Button
            isFlat
            hasThemeColor
            onClick={() =>
              window.open(billingInfo?.sale_data?.billet_link, '_blank')
            }
          >
            Baixar Boleto
          </Button>

          <hr />

          <Message>
            Para acelerar a inclusão dos créditos, acesse a opção de Enviar
            comprovante no painel para que possamos reconhecer sua compra o quanto
            antes. Veja o exemplo abaixo:
          </Message>

          <HintImg src={HintImageSource} />
        </>
      )}

      <CloseButtonContainer>
        <Button isNeutral onClick={onClose}>
          Fechar
        </Button>
      </CloseButtonContainer>
    </Container>
  )
}

export default withRedux(() => ({}), { ...common })(BillingInfoContent)
