import React, { useState } from 'react'

import styled from 'styled-components'
import Modal from 'components/lib/Modal'
import Grid from 'components/lib/Grid'
import Button from 'components/lib/Button'

import withRedux from 'components/redux'
import * as common from 'utils/UtilityStore/actions'

import { FormFieldSelect, FormFieldText } from 'components/lib/FormFields'

import { request } from 'core/constants'

import Field from 'components/lib/FormFields/components/Field'
import Control from 'components/lib/FormFields/components/Control'
import BillingInfoContent from './BillingInfoContent'

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 800;
  color: #5f6775;
  margin-bottom: 0.5rem;
`

const Subtitle = styled.p`
  color: #666;
`

const PricingGrid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  background-color: hsl(0deg, 0%, 96%);
  padding: 0.5rem;
`

const PricingOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
`

const PricingDetails = styled.div``

const PricingRange = styled.div`
  color: #666;
`

const PricingValue = styled.div`
  color: #00bbdd;
  font-weight: bold;
`

const StyledIcon = styled.i`
  margin: 0 0.5rem;
  color: #9ca3af;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  & > span {
    color: #ef4444;
  }
`

const TotalSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
`

const CreditInfo = styled.div`
  color: #666;
`

const TotalInfo = styled.div`
  text-align: right;
`

const TotalLabel = styled.div`
  font-size: 0.875rem;
  color: #666;
`

const TotalValue = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #0891b2;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
`

const LoadingContainer = styled.div`
  height: 250px;
  margin-top: 100px;
`

const Spinner = styled.div`
  margin: 0 auto;
  padding: 45px 60px;
`

const CreditOption = ({ range, price, isLast }) => (
  <PricingOption>
    <PricingDetails>
      <PricingRange>{range}</PricingRange>
      <PricingValue>R$ {price}/crédito</PricingValue>
    </PricingDetails>
    {!isLast && <StyledIcon className='fa fa-chevron-right' />}
  </PricingOption>
)

const creditOptions = [
  { range: 'De 1 a 10 créditos', price: '30,00' },
  { range: 'De 11 a 20 créditos', price: '27,50' },
  { range: 'De 21 a 50 créditos', price: '25,00' },
  { range: 'Acima de 51 créditos', price: '20,00' },
]

const pricingTiers = [
  { min: 1, max: 10, price: 30.0 },
  { min: 11, max: 20, price: 27.5 },
  { min: 21, max: 50, price: 25.0 },
  { min: 51, max: Infinity, price: 20.0 },
]

const BuyCreditAnalysisModal = ({
  isOpen,
  onClose,
  user,
  company,
  notification,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [paymentForm, setPaymentForm] = useState('PIX')
  const [credits, setCredits] = useState(10)
  const [errors, setErrors] = useState({ email: '', credits: '' })

  const [billingInfo, setBillingInfo] = useState()

  const clearStateAndClose = () => {
    setErrors({ email: '', credits: '' })
    setEmail('')
    setCredits(10)
    setBillingInfo(null)
    onClose()
  }

  const validateCredits = (value) => {
    const creditNum = parseInt(value)

    if (!creditNum || creditNum < 1) {
      setErrors((prev) => ({
        ...prev,
        credits:
          'A quantidade de créditos deve ser um número inteiro maior ou igual a 1.',
      }))
    } else {
      setErrors((prev) => ({ ...prev, credits: '' }))
    }
  }

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!value || !emailRegex.test(value)) {
      setErrors((prev) => ({
        ...prev,
        email: 'Insira um email válido.',
      }))
    } else {
      setErrors((prev) => ({ ...prev, email: '' }))
    }
  }

  const calculateTotal = () => {
    const creditNum = parseInt(credits)

    // Return 0 if credits is not a valid number or is negative
    if (!creditNum || creditNum < 0) return 0

    // Find the appropriate pricing tier
    const tier = pricingTiers.find((t) => creditNum >= t.min && creditNum <= t.max)

    if (!tier) return 0

    const total = creditNum * tier.price

    return total
  }

  const formatPrice = (price) => {
    return price.toFixed(2).replace('.', ',')
  }

  const getCurrentPrice = () => {
    const creditNum = parseInt(credits)

    if (!creditNum) return '0,00'

    const tier = pricingTiers.find((t) => creditNum >= t.min && creditNum <= t.max)

    return tier ? formatPrice(tier.price) : '0,00'
  }

  const submit = async () => {
    try {
      setIsLoading(true)

      const { data } = await request.post(
        `/company/${company.id}/ficha-certa-credits-purchases`,
        {
          credits: credits,
          billing_email: email,
          payment_form: paymentForm,
        },
      )

      setBillingInfo(data)

      notification({
        message: 'Compra de créditos realizada com sucesso!',
      })

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)

      notification({
        message: err?.message ? err.message : 'Erro ao comprar créditos',
        status: err ? 'warning' : 'danger',
      })
    }
  }

  if (isLoading) {
    return (
      <Modal title='Adicionar créditos' isOpen={isOpen} onClose={onClose}>
        <div>
          <LoadingContainer>
            <Spinner className='empty-state-spinner' />
          </LoadingContainer>
        </div>
      </Modal>
    )
  }

  return (
    <Modal title='Adicionar créditos' isOpen={isOpen} onClose={clearStateAndClose}>
      {billingInfo ? (
        <BillingInfoContent billingInfo={billingInfo} onClose={clearStateAndClose} />
      ) : (
        <>
          <Title>FC Análise</Title>
          <Subtitle>
            Adicione créditos à sua conta para realizar análises com a FC.
          </Subtitle>

          <PricingGrid>
            {creditOptions.map((option, index) => (
              <CreditOption
                key={index}
                {...option}
                isLast={index === creditOptions.length - 1}
              />
            ))}
          </PricingGrid>
          <Grid>
            <Grid.Col>
              <Label>
                Quantidade de créditos <span>*</span>
              </Label>
              <Field>
                <FormFieldText
                  type='number'
                  forceInvalid={!!errors.credits}
                  errorMessage={errors.credits}
                  onChange={(value) => {
                    setCredits(value)
                    validateCredits(value)
                  }}
                  valueDefault={10}
                />
              </Field>
            </Grid.Col>
            <Grid.Col>
              <Label>
                Forma de pagamento <span>*</span>
              </Label>
              <Field>
                <Control>
                  <FormFieldSelect
                    valueKey='id'
                    valueDefault='PIX'
                    hideBlankOption
                    options={[
                      { id: 'PIX', name: 'PIX' },
                      { id: 'TICKET', name: 'BOLETO' },
                    ]}
                    isDisabled={false}
                    onChange={setPaymentForm}
                  />
                </Control>
              </Field>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col>
              <Label>
                Email para comprovante <span>*</span>
              </Label>
              <Field>
                <FormFieldText
                  type='email'
                  forceInvalid={!!errors.email}
                  errorMessage={errors.email}
                  onChange={(value) => {
                    setEmail(value)
                    validateEmail(value)
                  }}
                  placeholder='Digite seu email'
                  valueDefault={user.email}
                />
              </Field>
            </Grid.Col>
          </Grid>

          {credits && parseInt(credits) > 0 && (
            <TotalSection>
              <CreditInfo>
                {credits} créditos (R$ {getCurrentPrice()}/crédito)
              </CreditInfo>
              <TotalInfo>
                <TotalLabel>Total</TotalLabel>
                <TotalValue>R$ {formatPrice(calculateTotal())}</TotalValue>
              </TotalInfo>
            </TotalSection>
          )}

          <ButtonGroup>
            <Button isBare onClick={clearStateAndClose}>
              Cancelar
            </Button>
            <Button
              hasThemeColor
              isLoading={false}
              isDisabled={errors.email || errors.credits}
              onClick={submit}
            >
              Finalizar
            </Button>
          </ButtonGroup>
        </>
      )}
    </Modal>
  )
}

export default withRedux(
  (store) => ({
    company: store.app.activeCompany,
    user: store.app.activeUser,
  }),
  { ...common },
)(BuyCreditAnalysisModal)
