import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import styled from 'styled-components'
import moment from 'moment'
import { baseURL } from 'core/constants'
import Grid from 'components/lib/Grid'
import Button from 'components/lib/Button'
import Table from 'components/lib/Table'
import Icon from 'components/lib/Icon'
import IconRv from 'static/media/icon-rede-vistorias-white.png'

const SubtitleDivider = styled.hr`
  margin: 0 0 20px;
  opacity: 0.5;
`

const StyledHeader = styled.header`
  margin-bottom: 12px;
`

const RVIconImage = styled.img`
  height: 16px;
  margin-right: 10px;
`

const CreateSurveyProcess = ({ process, company, onSuccess, propertyTypes }) => {
  /* eslint-disable no-undef */
  const redeVistoriasUrl = REDE_VISTORIAS_URL
  const redeVistoriasWebhook = WEBHOOK_VISTORIAS_URL

  const redeVistoriasData = process.redeVistoria

  useEffect(() => {
    if (redeVistoriasData.length === 0) {
      initializeRedeVistorias()
    }
  }, [redeVistoriasData])

  const initializeRedeVistorias = () => {
    const property = process.proposal.properties[0]
    const { property_owners, clients, guarantors } = process.proposal

    // Dynamically load Rede Vistorias script
    ;(function (r, v, s, d, k, i, n) {
      if (r.rv) return
      k = r.rv = function () {
        k.queue.push(arguments)
      }
      k.queue = []
      i = v.createElement(s)
      i.async = true
      i.src = d
      n = v.getElementsByTagName(s)[0]
      n.parentNode.insertBefore(i, n)
    })(window, document, 'script', redeVistoriasUrl)

    rv('partner', 'Izee')

    // Partner document
    if (company.cpf) rv('document', company.cpf)
    if (company.cnpj) rv('document', company.cnpj)

    // Order type
    rv('orderType', 'entrada')

    // Property data
    rv('property', {
      building_id: property.code,
      furnished: 'unfurnished',
      building_type: property.type?.name || '',
      purpose: 'Residencial',
      area: property.area,
      address: {
        neighborhood: property.address.neighborhood,
        state: property.address.state,
        city: property.address.city,
        zipcode: property.address.zip_code,
        street: property.address.address,
        number: property.address.number,
        complement: property.address.additional_address,
      },
    })

    // People data
    const mapPeople = (list, defaultName = '') =>
      list.length > 0
        ? list.map(({ name }) => ({ name, signature: true }))
        : [{ name: defaultName, signature: false }]

    rv('people', [
      {
        id: 'propertyManager',
        title: 'Administradora',
        content: [{ name: company.name, signature: true }],
      },
      {
        id: 'owner',
        title: 'Locador',
        content: mapPeople(property_owners),
      },
      {
        id: 'tenant',
        title: 'Cliente',
        content: mapPeople(clients),
      },
      {
        id: 'guarantor',
        title: 'Fiador',
        content: mapPeople(guarantors),
      },
    ])

    // Webhook
    rv('webhook', redeVistoriasWebhook)

    // Event listener
    rv('on', 'order.created', (code, price) => {
      const data = {
        code,
        price,
        status: 'Aguardando',
        property_id: property.id,
        process_id: process.id,
      }

      axios
        .post(`${baseURL}company/${company.id}/rede-vistoria`, data)
        .then(() => onSuccess({ isSuccess: true }))
        .catch(() => onSuccess({ isSuccess: false }))
    })

    // Initialize SDK
    rv('init', 'sdk-rv')
  }

  const renderTable = () => (
    <Grid>
      <Grid.Col>
        <Grid>
          <Grid.Col>
            <StyledHeader className='header'>
              <div>
                <h1 className='title is-h-1'>Vistoria de Entrada</h1>
              </div>
            </StyledHeader>
            <span>
              Agilize o processo de entrada do seu inquilino ao solicitar uma
              vistoria com a RV!
            </span>
            <SubtitleDivider />
          </Grid.Col>
          <Grid.Col isNarrow>
            <Button onClick={initializeRedeVistorias} hasThemeColor>
              <Icon name='fas fa-clipboard' /> <span>Solicitar vistoria</span>
            </Button>
          </Grid.Col>
        </Grid>
        <Table
          items={redeVistoriasData}
          cells={[
            {
              name: 'Data da solicitação',
              path: 'created_at',
              align: 'center',
              render: (content) =>
                content ? moment(content).format('DD/MM/YYYY HH:mm') : '-',
            },
            { name: 'Código da vistoria', align: 'center', path: 'code' },
            { name: 'Status', align: 'center', path: 'status' },
            { name: 'Créditos', align: 'center', path: 'price' },
            {
              name: 'Relatório',
              path: 'report',
              align: 'center',
              render: (content) =>
                content && (
                  <a
                    className='link-icon'
                    href={content}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button isFlat hasThemeColor>
                      <Icon name='fas fa-clipboard-list' />
                      <span>Baixar relatório</span>
                    </Button>
                  </a>
                ),
            },
            {
              name: 'Painel RV',
              path: 'hotsite',
              align: 'center',
              render: (content) =>
                content && (
                  <a
                    className='link-icon'
                    href={
                      content.startsWith('http') ? content : `https://${content}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button isFlat hasThemeColor>
                      <RVIconImage
                        src={IconRv}
                        alt='Rede Vistorias'
                      />
                      <span>Acessar Meu Imóvel Digital</span>
                    </Button>
                  </a>
                ),
            },
          ]}
        />
      </Grid.Col>
    </Grid>
  )

  return (
    <>
      {redeVistoriasData.length > 0 && renderTable()}
      <div id='sdk-rv'></div>
      {!redeVistoriasUrl && (
        <Grid>
          <Grid.Col>
            <div className='text-center'>Rede Vistorias em breve.</div>
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

CreateSurveyProcess.propTypes = {
  process: PropTypes.shape({
    redeVistoria: PropTypes.array.isRequired,
    proposal: PropTypes.shape({
      properties: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          type: PropTypes.shape({
            name: PropTypes.string,
          }),
          area: PropTypes.number,
          address: PropTypes.shape({
            neighborhood: PropTypes.string,
            state: PropTypes.string,
            city: PropTypes.string,
            zip_code: PropTypes.string,
            address: PropTypes.string,
            number: PropTypes.string,
            additional_address: PropTypes.string,
          }).isRequired,
        }),
      ).isRequired,
      property_owners: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ).isRequired,
      clients: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ).isRequired,
      guarantors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    cnpj: PropTypes.string,
    cpf: PropTypes.string,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  propertyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
}

export default CreateSurveyProcess
