import React from 'react'

// Components
import { FormFieldText, FormFieldPhone } from 'components/lib/FormFields'
import { fieldProps } from '../formCreator'

const PersonalData = (props) => (
  <React.Fragment>
    <FormFieldText {...fieldProps(props, 'name')} label='Nome' />
    <FormFieldText {...fieldProps(props, 'email')} label='Email' />
    <FormFieldPhone
      {...fieldProps(this.props, 'phone')}
      label='Telefone'
      noValidate
    />
    <FormFieldText {...fieldProps(props, 'cpf')} label='CPF' mask={['cpf']} />
    <FormFieldText
      {...fieldProps(props, 'income')}
      mask={['number']}
      label='Renda'
    />
    <FormFieldText
      {...fieldProps(props, 'estimated_rent_value')}
      mask={['number']}
      label='Valor estimado do aluguel'
    />
  </React.Fragment>
)

export default PersonalData
