import React from 'react'

import {
  FormFieldText,
  FormFieldSelect,
  FormFieldSwitch,
  FormFieldPhone,
} from 'components/lib/FormFields'
import { fieldProps } from '../formCreator'
import FormGrid from 'components/lib/FormGrid'
import * as Constants from 'components/constants'
import { baseURL } from 'core/constants'
import request from 'axios'

class PersonalData extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      icomeOptions: [],
      genderOptions: [
        { id: 'M', code: 'M', name: 'Masculino' },
        { id: 'F', code: 'F', name: 'Feminino' },
      ],
    }
  }

  componentDidMount() {
    request
      .get(`${baseURL}income-source`)
      .then((response) => {
        this.setState({ icomeOptions: response.data.data || response.data })
      })
      .catch((response) => {
        console.log(response)
      })
  }

  renderFieldName(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'name')}
          label='Nome'
          isRequired
          onChange={(e) => this.props.handleChange('name', e)}
          tabIndex={tabIndex}
          mask={['upperCase']}
        />
      </FormGrid>
    )
  }

  renderFieldEmail(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'email')}
          isDisabled={this.props.disableEmail}
          label='Email'
          isRequired
          onChange={(e) => this.props.handleChange('email', e)}
          tabIndex={tabIndex}
          mask={['lowerCase']}
        />
      </FormGrid>
    )
  }

  renderFieldPhone(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldPhone
          {...fieldProps(this.props, 'phone')}
          label='Telefone'
          isRequired
          noValidate
          tabIndex={tabIndex}
          onChange={(e) => this.props.handleChange('phone', e)}
        />
      </FormGrid>
    )
  }

  renderFieldBirth(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'birth_date')}
          label='Data de nascimento'
          mask={['date']}
          isRequired
          placeholder='DD/MM/AAAA'
          onChange={(e) => this.props.handleChange('birth_date', e)}
          tabIndex={tabIndex}
          numbersOnly
        />
      </FormGrid>
    )
  }

  renderFieldIdentityExpeditionDate(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'identity_expedition_date')}
          label='Data de expedição do documento'
          mask={['date']}
          noValidate
          placeholder='DD/MM/AAAA'
          onChange={(e) => this.props.handleChange('identity_expedition_date', e)}
          tabIndex={tabIndex}
          numbersOnly
        />
      </FormGrid>
    )
  }

  renderFieldCPF(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'cpf')}
          label='CPF'
          mask={['cpf']}
          isRequired
          noValidate={this.props.noValidate}
          onChange={(e) => this.props.handleChange('cpf', e)}
          tabIndex={tabIndex}
          numbersOnly
        />
      </FormGrid>
    )
  }

  renderFieldNationality(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'nationality')}
          label='Nacionalidade'
          isRequired
          onChange={(e) => this.props.handleChange('nationality', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldDocument(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldSelect
          {...fieldProps(this.props, 'identity_type')}
          label='Documento'
          valueKey='name'
          onChange={(e) => this.props.handleChange('identity_type', e)}
          options={[
            {
              id: 1,
              code: 'DOCUMENT_TYPE_RG',
              name: 'RG',
            },
            {
              id: 3,
              code: 'DOCUMENT_TYPE_PASSPORT',
              name: 'Passaporte',
            },
            {
              id: 4,
              code: 'DOCUMENT_TYPE_OTHER',
              name: 'Outro documento',
            },
          ]}
          isRequired
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldDocumentNumber(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'identity')}
          label='Número do documento'
          mask={[{ maxLength: 15 }]}
          isRequired
          onChange={(e) => this.props.handleChange('identity', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldDocumentIssuingBody(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'identity_issuing_body')}
          label='Órgão emissor / UF'
          isRequired
          onChange={(e) => this.props.handleChange('identity_issuing_body', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldMaritalStatus(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldSelect
          {...fieldProps(this.props, 'marital_status_id')}
          onChange={(e) => this.props.handleChange('marital_status_id', e)}
          label='Estado civil'
          valueKey='id'
          isRequired
          options={Constants.MARITAL_STATUS}
          noValidate
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldOccupation(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'occupation')}
          label='Ocupação'
          isRequired
          onChange={(e) => this.props.handleChange('occupation', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldEstimatedRentValue(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'estimated_rent_value')}
          mask={['number']}
          label='Valor do aluguel + encargos'
          info='Estimativa do Aluguel + Condomínio + IPTU'
          noValidate
          onChange={(e) => this.props.handleChange('estimated_rent_value', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldIncomeValue(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          isRequired={this.props.entityName != 'propertyOwner'}
          {...fieldProps(this.props, 'income')}
          mask={['number']}
          label='Renda'
          noValidate
          onChange={(e) => this.props.handleChange('income', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldIncomeSource(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldSelect
          isRequired={this.props.entityName != 'propertyOwner'}
          {...fieldProps(this.props, 'income_source_id')}
          onChange={(e) => this.props.handleChange('income_source_id', e)}
          label='Vínculo Empregatício'
          info='Caso não tenha vínculo, selecionar "Renda não informada"'
          valueKey='id'
          options={this.state.icomeOptions}
          noValidate
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldCorporateName(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'corporate_name')}
          label='Razão Social'
          isRequired
          noValidate
          onChange={(e) => this.props.handleChange('corporate_name', e)}
          tabIndex={tabIndex}
          mask={['upperCase']}
        />
      </FormGrid>
    )
  }

  renderFieldTradingName(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'trading_name')}
          label='Nome Fantasia'
          noValidate
          onChange={(e) => this.props.handleChange('trading_name', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldCnpj(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'cnpj')}
          label='CNPJ'
          mask={['cnpj']}
          isRequired
          noValidate
          onChange={(e) => this.props.handleChange('cnpj', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldMunicipalEnrollment(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'municipal_enrollment')}
          label='Cadastro Municipal'
          noValidate
          onChange={(e) => this.props.handleChange('municipal_enrollment', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldStateEnrollment(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'state_enrollment')}
          label='Cadastro Estadual'
          noValidate
          onChange={(e) => this.props.handleChange('state_enrollment', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldTypeJuridical() {
    const { typeJuridical } = this.props.defaultValue

    return (
      <FormFieldSwitch
        checked={typeJuridical}
        onClick={() => this.handleUserType(typeJuridical)}
        description={typeJuridical ? 'Pessoa Jurídica' : 'Pessoa Física'}
        type='checkbox'
      />
    )
  }

  handleUserType(value) {
    this.props.handleChange('typeJuridical', !value)
  }

  renderPayeeField(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'payee')}
          label='Favorecido'
          onChange={(e) => this.props.handleChange('payee', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderBankField(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'bank')}
          label='Banco'
          onChange={(e) => this.props.handleChange('bank', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderAgencyField(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'agency')}
          label='Agência'
          onChange={(e) => this.props.handleChange('agency', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderAccountField(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'account')}
          label='Conta'
          onChange={(e) => this.props.handleChange('account', e)}
          tabIndex={tabIndex}
        />
      </FormGrid>
    )
  }

  renderFieldGender(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldSelect
          {...fieldProps(this.props, 'gender')}
          onChange={(e) => this.props.handleChange('gender', e)}
          label='Gênero'
          valueKey='id'
          options={this.state.genderOptions}
          tabIndex={tabIndex}
          isRequired={false}
          noValidate
        />
      </FormGrid>
    )
  }

  renderFieldMotherName(tabIndex) {
    !tabIndex ? (tabIndex = '0') : tabIndex

    return (
      <FormGrid field fullWidth>
        <FormFieldText
          {...fieldProps(this.props, 'mother_name')}
          label='Nome da Mãe'
          onChange={(e) => this.props.handleChange('mother_name', e)}
          tabIndex={tabIndex}
          isRequired={false}
          noValidate
        />
      </FormGrid>
    )
  }

  render() {
    const {
      showEstimatedRentValue,
      hiddenRentValue = false,
      isPropertyOwnerForm = false,
    } = this.props

    const { typeJuridical } = this.props.defaultValue

    return (
      <React.Fragment>
        {this.renderFieldTypeJuridical()}

        {typeJuridical && (
          <>
            <FormGrid row>
              {this.renderFieldCorporateName()}
              {this.renderFieldCnpj()}
            </FormGrid>

            <FormGrid row>
              {this.renderFieldTradingName()}
              {this.renderFieldStateEnrollment()}
            </FormGrid>

            <FormGrid row>{this.renderFieldMunicipalEnrollment()}</FormGrid>

            <div className='section-identifier mt-4 mb-2'>
              <h1 className='form-section-title'>Dados do Responsável</h1>
            </div>
          </>
        )}

        <FormGrid row>
          {this.renderFieldName()}
          {this.renderFieldEmail()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldPhone()}
          {this.renderFieldBirth()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldCPF()}
          {this.renderFieldNationality()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldGender()}
          {this.renderFieldMotherName()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldDocument()}
          {this.renderFieldDocumentNumber()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldDocumentIssuingBody()}
          {this.renderFieldMaritalStatus()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldIdentityExpeditionDate()}
          {showEstimatedRentValue && this.renderFieldIncomeValue()}
        </FormGrid>

        <FormGrid row>
          {this.renderFieldOccupation()}
          {showEstimatedRentValue && this.renderFieldIncomeSource()}
        </FormGrid>

        <FormGrid row>
          {showEstimatedRentValue &&
            !hiddenRentValue &&
            this.renderFieldEstimatedRentValue()}
        </FormGrid>

        <FormGrid row>
          {isPropertyOwnerForm && this.renderBankField()}
          {isPropertyOwnerForm && this.renderAccountField()}
        </FormGrid>

        <FormGrid row>
          {isPropertyOwnerForm && this.renderAgencyField()}
          {isPropertyOwnerForm && this.renderPayeeField()}
        </FormGrid>
      </React.Fragment>
    )
  }
}

export default PersonalData
