import React from 'react'

import ReactDOM from 'react-dom'

import withRedux from 'components/redux'

const getColor = (canCustomize, color) => ({
  color: canCustomize ? color || '#2091EA' : '#2091EA',
})

class CreateTag extends React.PureComponent {
  constructor(props) {
    super(props)

    const isApp = location.href.includes('/app/')

    const subscriptionPlan = props.company?.subscription_plan

    this.state = getColor(
      !isApp || !subscriptionPlan || subscriptionPlan.can_customize_app,
      props.color,
    )
  }

  static getDerivedStateFromProps(props, state) {
    if (props.color !== state.color && !!props.color) {
      const isApp = location.href.includes('/app/')

      const subscriptionPlan = props.company?.subscription_plan

      return getColor(
        !isApp || !subscriptionPlan || subscriptionPlan.can_customize_app,
        props.color,
      )
    }

    return null
  }

  render() {
    return (
      <>
        .has-theme-color {'{'}
        background-color: {this.state.color} !important
        {'}'}
        .has-content-theme-color {'{'}
        color: {this.state.color} !important
        {'}'}
        .has-theme-border-color {'{'}
        border: 2px solid {this.state.color} !important
        {'}'}
      </>
    )
  }
}

const body = document.body

class Portal extends React.Component {
  constructor(props) {
    super(props)
    this.element = document.createElement('style')
  }

  componentDidMount() {
    body.appendChild(this.element)
  }

  componentWillUnmount() {
    body.removeChild(this.element)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element)
  }
}

const SetThemeColor = (props) => (
  <Portal>
    <CreateTag {...props} />
  </Portal>
)

export default withRedux(() => ({}), {})(SetThemeColor)
