import React from 'react'

// Components
import SubmitButton from 'components/SubmitButton'
import Grid from 'components/lib/Grid'

import formCreator from '../components/formCreator'

import {
  FormFieldText,
  FormFieldPhone,
  FormFieldEmail,
} from 'components/lib/FormFields'
import { fieldProps } from '../components/formCreator'

export class AddNewGuarantor extends React.Component {
  componentDidMount() {
    this.props.createRequest(this.createRequest, false)
  }

  createRequest(fields) {
    this.props.submit('{company}/guarantor/create', fields).catch((error) => {
      console.log(error)
    })
  }

  render() {
    const { formIsWorking, formIsReady } = this.props

    return (
      <React.Fragment>
        <Grid>
          <Grid.Col>
            <FormFieldText {...fieldProps(this.props, 'name')} label='Nome' />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldEmail
              {...fieldProps(this.props, 'email')}
              label='Email'
              errorMessage={
                this.props.fields.email &&
                this.props.fields.email.isEmpty != null &&
                !this.props.fields.email.isValid
                  ? 'Email inválido'
                  : ''
              }
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col>
            <FormFieldPhone
              {...fieldProps(this.props, 'phone')}
              label='Telefone'
              noValidate
            />
          </Grid.Col>
        </Grid>

        <SubmitButton
          label='Salvar e continuar'
          isLoading={formIsWorking}
          isDisabled={!formIsReady || formIsWorking}
        />
      </React.Fragment>
    )
  }
}

export default formCreator(AddNewGuarantor, { stopPropagation: true })
