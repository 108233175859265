import React, { useState } from 'react'
import styled, { keyframes, css } from 'styled-components'

// Components
import RadioGroup from 'components/lib/RadioGroup'
import Header from 'components/lib/Header'
import Icon from 'components/lib/Icon'
import formCreator from 'features/components/formCreator'

// Styled Components
const SectionHeader = styled.h2`
  font-weight: 500;
  margin-bottom: 10px;
`

const FileList = styled.ul`
  list-style: none;
  padding: 0;
`

const FileItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  a {
    color: #007bff;
    text-decoration: underline;
    margin-right: 8px;
  }

  button {
    color: red;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`

const UploadInfo = styled.p`
  margin-top: 10px;
`

const UploadContainer = styled.div`
  margin-top: 10px;
`

const Spinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const UploadLabel = styled.label`
  display: inline-block;
  padding: 8px 16px;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;

  ${(props) =>
    props.isLoading &&
    css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;

      svg {
        margin-left: 8px;
        animation: ${Spinner} 1s linear infinite;
      }
    `}
`

const FileInput = styled.input`
  display: none;
`

export const AttachmentsList = ({
  attachInspectionReport,
  setAttachInspectionReport,
  uploadedFiles,
  handleRemoveFile,
  handleFileUpload,
  isClosed,
  hasFinishedInspection,
}) => {
  const [isUploading, setIsUploading] = useState(false)

  const handleUpload = async (e) => {
    setIsUploading(true)
    await handleFileUpload(e)
    setIsUploading(false)
  }

  return (
    <>
      {/* Section Header */}
      <Header subtitle={<hr style={{ margin: '4px 0px 14px 0px' }} />}>
        ANEXOS
      </Header>

      {/* Radio Group */}
      {hasFinishedInspection && (
        <RadioGroup
          name='attach_inspection_report'
          title='Envio de vistorias'
          options={[
            {
              value: '1',
              label:
                'Anexar ao contrato vistorias da Rede Vistorias feitas durante este processo',
            },
            { value: '0', label: 'Não anexar' },
          ]}
          value={attachInspectionReport ? '1' : '0'}
          isDisabled={isClosed}
          onChange={(v) => setAttachInspectionReport(v == '1')}
        />
      )}

      {/* Attach Files Section */}
      <SectionHeader>Anexar arquivos externos (Opcional)</SectionHeader>

      {/* Uploaded Files List */}
      <FileList>
        {uploadedFiles.map((file) => (
          <FileItem key={file.id}>
            <span>📄</span>
            <a href={file.source} target='_blank' rel='noopener noreferrer'>
              {file.name}
            </a>
            <span>({file.size})</span>
            <button onClick={() => handleRemoveFile(file.id)}>
              <Icon name='fa fa-times' /> Remover
            </button>
          </FileItem>
        ))}
      </FileList>

      {/* Total File Size Info */}
      <UploadInfo>
        Atenção: Os anexos são limitados a um total de <strong>30mb</strong>. Total:{' '}
        {uploadedFiles
          .reduce((acc, file) => acc + parseFloat(file.size || 0), 0)
          .toFixed(2)}
        mb
      </UploadInfo>

      {/* File Upload Button */}
      <UploadContainer>
        <UploadLabel
          htmlFor='file-upload'
          isLoading={isUploading}
          className='has-theme-color'
        >
          {!isUploading && <Icon name='fas fa-upload' />}
          {isUploading ? 'Carregando...' : ' Upload de arquivo'}
          {isUploading && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 1 .908-.417A4 4 0 1 0 8 4v-.5a.5.5 0 0 1 1 0V4a.5.5 0 0 1-1 0V3z'
              />
            </svg>
          )}
        </UploadLabel>
        <FileInput
          id='file-upload'
          type='file'
          accept='application/pdf'
          onChange={handleUpload}
          disabled={isUploading}
        />
      </UploadContainer>
    </>
  )
}

export default formCreator(AttachmentsList)
